import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    retrivedSubmissionData: {
        submissionPayload: {
            submissionId: 0,
            clientId: 0,
            brokerId: 0,
            insuredName: '',
            reinsuranceContractType: '',
            submissionStatus: '',
            uwsSubmissionId: '',
            submissionCountry: '',
            isSubmitted: true,
            isValidationRequired: false,
            isDeleted: false,
            createByUserId: '',
            createTS: '',
            lastChgByUserId: null,
            lastChgTS: null,
            submissionLOBs: [],
            propertySubmission: {
                propertySubmissionId: 0,
                submissionId: 0,
                paperCompany: '',
                clientPolicyNumber: '',
                effectiveDate: '',
                expirationDate: '',
                reinsuranceEffectiveDate: '',
                bookedDate: '',
                reinsuranceExpirationDate: '',
                uwsSubmissionId: '',
                policyType: '',
                pricingData: '',
                isFirePeril: false,
                sublimitForFirePeril: 0,
                isTerrorismPeril: false,
                sublimitForTerrorismPeril: 0,
                isBoilerMachineryPeril: false,
                sublimitForBoilerMachineryPeril: 0,
                isWindPeril: false,
                sublimitForWindPeril: 0,
                isFloodIncSurgePeril: false,
                sublimitForFloodIncSurgePeril: 0,
                isFloodExclSurgePeril: false,
                sublimitForFloodExclSurgePeril: 0,
                numberOfYearsReported: 0,
                numberOfLossesOverTBD: 0,
                largeIndividualLoss: 0,
                claimValuationDate: '',
                attachmentPoint: null,
                limit: null,
                partOf: null,
                tiv: null,
                sharesRequested: null,
                commissionCede: 0,
                commissionBrokerage: null,
                layerAnnualizedClientOfferAmt: 0,
                annualGrossPremium: 0,
                lastQuotedDate: '',
                isDeleted: false,
                createByUserId: '',
                createTS: '',
                lastChgByUserId: null,
                lastChgTS: null,
                addresses: [],
            },
            attachments: [],
            NewRenewIndicator: 0
        },
    }
};
const retrivedSubmissionDetils = createSlice({
    name: 'retrivedSubmissionDetails',
    initialState,
    reducers: {
        setRetrivedSubmissionDetails: (state, action) => {
            state.retrivedSubmissionData.submissionPayload = action.payload;
        },
        clearRetrivedSubmissionDetails: (state) => {
            return initialState;
        },
    },
});
export const { setRetrivedSubmissionDetails, clearRetrivedSubmissionDetails } = retrivedSubmissionDetils.actions;
export default retrivedSubmissionDetils.reducer;
