var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { GET_Method, POST_Method } from './components/customhooks/useAxios';
import AppRoutes from '../src/routes/app.router';
import { AzureLogin } from '../src/components/azure_login/AzureLogin';
import Loader from './@atoms/content/loader/loader.atom';
import { setReferenceDetails } from './store/slices/referenceDataReducer';
import { setUserProfileDetails } from './store/slices/userProfileDataReducer';
import ThemeProviderStyled from './@themes';
import { APIUrlTypeEnum, decryptUserProfileDetails, getAPIBaseUrl } from './utils/CommonUtilties';
import AccessDeniedComponent from './components/access_denied/AccessDenied.Component';
export default function MainApp() {
    const dispatch = useDispatch();
    const referenceData = useSelector((state) => state.referenceData.referenceData);
    const [isLoader, setIsLoader] = useState(false);
    const [accessToken, setAccessToken] = useState(localStorage.getItem("accesstoken") || null);
    const [hasKeyVaultData, setHasKeyVaultData] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [searchParams] = useSearchParams();
    const profile_API_URL = process.env.REACT_APP_PROFILE_API_URL;
    const testingFlag = process.env.React_APP_IsTesting;
    const parsedPid = searchParams.get("pid");
    const profileUserId = testingFlag == "true" ? (parsedPid !== null ? parseInt(parsedPid, 10) : 0) : 0;
    const [isUserProfileData, setIsUserProfileData] = useState(false);
    useEffect(() => {
        const fetchData = () => __awaiter(this, void 0, void 0, function* () {
            //const gotKeyVaultData = await getKeyVaultData();
            if (accessToken != null
                && (referenceData === undefined || (referenceData === null || referenceData === void 0 ? void 0 : referenceData.length) === 0)) {
                setIsLoader(true);
                const axiosParams = {
                    url: `${getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl)}/UserProfile/References`,
                    methodType: 'post',
                    body: {
                        genericReferenceFilterDetailsPayload: [
                            {
                                genericReferenceTypes: '',
                                externalSystemCodes: '',
                                parentFilterType: '',
                                parentFilterValue: '',
                            },
                        ],
                    },
                };
                POST_Method(axiosParams).then((responseRefData) => {
                    dispatch(setReferenceDetails(responseRefData === null || responseRefData === void 0 ? void 0 : responseRefData.data));
                    /* START - Get User Profile Data */
                    getUserProfileData();
                    /* END - Get User Profile Data */
                }).catch((error) => {
                    console.log("MainApp - Error retrieving reference data: ", error);
                    getUserProfileData();
                });
            }
            else if ((referenceData === null || referenceData === void 0 ? void 0 : referenceData.length) > 0) {
                getUserProfileData();
            }
        });
        fetchData();
    }, [accessToken]);
    const getUserProfileData = () => {
        const requstUrl = `${getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl)}/UserProfile/User/${profileUserId}`;
        const axiosParams = {
            url: requstUrl,
            methodType: "get"
        };
        GET_Method(requstUrl, axiosParams).then((responseProfileData) => {
            var decryptedDetails = decryptUserProfileDetails(responseProfileData.data);
            let response = JSON.parse(decryptedDetails);
            dispatch(setUserProfileDetails(response));
            setIsDataFetched(true);
            if (responseProfileData.data == null)
                setIsUserProfileData(false);
            else
                setIsUserProfileData(true);
            setIsLoader(false);
            setIsDataFetched(true);
        }).catch((error) => {
            console.log("MainApp - Error retrieving Profile data: ", error);
            setIsLoader(false);
            setIsDataFetched(true);
        });
    };
    return (_jsx(ThemeProviderStyled, { children: isLoader ? _jsx(Loader, {}) : (_jsxs(_Fragment, { children: [_jsx(AzureLogin, { setAccessToken: setAccessToken }), isDataFetched && (isUserProfileData ? _jsx(AppRoutes, {}) : _jsx(AccessDeniedComponent, { isUnauthorized: !isUserProfileData }))] })) }));
}
