import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// Import your reducers
import contactUsDetailsReducer from './slices/contactUsDetailsReducer';
import loadingReducer from './slices/loadingReducer';
import sideNavOpenCloseReducer from './slices/sideNavOpenCloseReducer';
import businessOverviewReducer from './slices/businessOverviewReducer';
import policyInfoReducer from './slices/policyInfoReducer';
import pearlsCoveredReducer from './slices/pearlsCoveredReducer';
import submissionIdReducer from './slices/submissionIdReducer';
import lossInfoReducer from './slices/lossInfoReducer';
import layersRequestedReducer from './slices/layersRequestedReducer';
import submisisonDateInfoReducer from './slices/submisisonDateInfoReducer';
import submissionLocationAddressReducer from './slices/submissionLocationAddressReducer';
import commissionReducer from './slices/commissionReducer';
import locationAddressReducer from './slices/locationAddressReducer';
import leftNavReducer from './slices/leftNavReducer';
import coherentQuoteDetailsReducer from './slices/coherentQuoteDetailsReducer';
import submissionFormDetailsReducer from './slices/submissionFormDetailsReducer';
import userProfileDataReducer from './slices/userProfileDataReducer';
import referenceDataReducer from './slices/referenceDataReducer';
import isLinkedPageReducer from './slices/isLinkedPageReducer';
import retrivedSubmissionReducer from './slices/retrivedSubmissionReducer';
import attatchmentsReducer from './slices/attatchmentsReducer';
import apiErrorReducer from './slices/apiErrorReducer';
import underWriterReferredReducer from './slices/underWriterReferredReducer';
import isValidatedReducer from './slices/isValidatedReducer';
const persistConfig = {
    key: 'root',
    storage,
};
const persistedContactUsDetailsReducer = persistReducer(persistConfig, contactUsDetailsReducer);
const persistedLoadingReducer = persistReducer(persistConfig, loadingReducer);
const persistedSideNavOpenCloseReducer = persistReducer(persistConfig, sideNavOpenCloseReducer);
const persistedPolicyInfoReducer = persistReducer(persistConfig, policyInfoReducer);
const persistedPearlsCoveredReducer = persistReducer(persistConfig, pearlsCoveredReducer);
const persistedSubmissionIdReducer = persistReducer(persistConfig, submissionIdReducer);
const persistedLossInfoReducer = persistReducer(persistConfig, lossInfoReducer);
const persistedLayersRequestedReducer = persistReducer(persistConfig, layersRequestedReducer);
const persistedSubmisisonDateInfoReducer = persistReducer(persistConfig, submisisonDateInfoReducer);
const persistedSubmissionLocationAddressReducer = persistReducer(persistConfig, submissionLocationAddressReducer);
const persistedCommissionReducer = persistReducer(persistConfig, commissionReducer);
const persistedLocationAddressReducer = persistReducer(persistConfig, locationAddressReducer);
const persistedLeftNavReducer = persistReducer(persistConfig, leftNavReducer);
const persistedUserProfileDataReducer = persistReducer(persistConfig, userProfileDataReducer);
const persistedReferenceDataReducer = persistReducer(persistConfig, referenceDataReducer);
const persistedCoherentQuoteDetailsReducer = persistReducer(persistConfig, coherentQuoteDetailsReducer);
const persistedSubmissionFormDetailsReducer = persistReducer(persistConfig, submissionFormDetailsReducer);
const persistedAttatchmentsReducer = persistReducer(persistConfig, attatchmentsReducer);
const persistedapiErrorReducer = persistReducer(persistConfig, apiErrorReducer);
const persistedReducers = {
    contactUsDetails: persistedContactUsDetailsReducer,
    loading: persistedLoadingReducer,
    sideNavOpen: persistedSideNavOpenCloseReducer,
    businessOv: businessOverviewReducer,
    policyInfo: persistedPolicyInfoReducer,
    pearlsCoveredDetails: persistedPearlsCoveredReducer,
    submissionIdDetails: persistedSubmissionIdReducer,
    lossInfo: persistedLossInfoReducer,
    layerRequestedInfo: persistedLayersRequestedReducer,
    datesInfo: persistedSubmisisonDateInfoReducer,
    submissionLocationAddressInfo: persistedSubmissionLocationAddressReducer,
    commissionData: persistedCommissionReducer,
    locationAddressDetails: persistedLocationAddressReducer,
    leftNav: persistedLeftNavReducer,
    userProfileData: persistedUserProfileDataReducer,
    referenceData: persistedReferenceDataReducer,
    storeUWRuleMessagesAndPricing: persistedCoherentQuoteDetailsReducer,
    submissionFormDetails: persistedSubmissionFormDetailsReducer,
    isLinked: isLinkedPageReducer,
    retrivedSubmissionDetils: retrivedSubmissionReducer,
    createdAttatchmentDetails: persistedAttatchmentsReducer,
    apiErrorDetails: persistedapiErrorReducer,
    referredPreminumData: underWriterReferredReducer,
    isValidatedSlice: isValidatedReducer
};
const store = configureStore({
    reducer: persistedReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, }),
});
export const persistor = persistStore(store);
export default store;
