import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    businessOverviewData: {
        country: '',
        reInsContractType: '',
        brokerName: '',
        clientName: '',
        nameInsured: '',
        countryId: '',
        reInsContractTypeId: '',
        brokerId: null,
        clientId: null,
    }
};
const businessOverviewSlice = createSlice({
    name: 'businessOverviewDetails',
    initialState,
    reducers: {
        setCountry: (state, action) => {
            state.businessOverviewData.country = action.payload;
        },
        setReInsContractType: (state, action) => {
            state.businessOverviewData.reInsContractType = action.payload;
        },
        setBrokerNames: (state, action) => {
            state.businessOverviewData.brokerName = action.payload;
        },
        setClientName: (state, action) => {
            state.businessOverviewData.clientName = action.payload;
        },
        setCountryId: (state, action) => {
            state.businessOverviewData.countryId = action.payload;
        },
        setReInsContractTypeId: (state, action) => {
            state.businessOverviewData.reInsContractTypeId = action.payload;
        },
        setBrokerId: (state, action) => {
            state.businessOverviewData.brokerId = action.payload;
        },
        setClientId: (state, action) => {
            state.businessOverviewData.clientId = action.payload;
        },
        setNameInsured: (state, action) => {
            state.businessOverviewData.nameInsured = action.payload;
        },
        clearSubmissionDetails: () => {
            return initialState;
        },
        setBusinessOverView: (state, action) => {
            var _a, _b, _c, _d, _e;
            // state.businessOverviewData.country = action.payload?.submissionCountry;
            // state.businessOverviewData.reInsContractType= action.payload?.reinsuranceContractType;
            state.businessOverviewData.nameInsured = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.insuredName;
            state.businessOverviewData.countryId = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.submissionCountry;
            state.businessOverviewData.reInsContractTypeId = (_c = action.payload) === null || _c === void 0 ? void 0 : _c.reinsuranceContractType;
            state.businessOverviewData.brokerId = (_d = action.payload) === null || _d === void 0 ? void 0 : _d.brokerId;
            state.businessOverviewData.clientId = (_e = action.payload) === null || _e === void 0 ? void 0 : _e.clientId;
            // state.businessOverviewData.clientName= action.payload?.clientId;
            //const brokerNameOptions = extractBrokerData()
            //state.businessOverviewData.brokerName=findLabelById(brokerNameOptions,action.payload?.brokerId)
        },
    },
});
export const { setCountry, setCountryId, setReInsContractType, setReInsContractTypeId, setBrokerNames, setBrokerId, setClientName, setClientId, setNameInsured, clearSubmissionDetails, setBusinessOverView } = businessOverviewSlice.actions;
export default businessOverviewSlice.reducer;
