import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import BoxStyled from "../../../@atoms/layout/box/index.atom";
import { TypographyAtom } from '@epam/reactjs-common';
// import DropdownComponent from '../../Dropdown/Dropdown.component.tsx';
import { useEffect, useState } from "react";
import { setPolicyEffectiveDate, setPolicyExpirationDate, setReinsuranceEffectiveDate, setReinsuranceExpirationDate, setPolicyType, setPolicyTypeId } from "../../../store/slices/submisisonDateInfoReducer";
import DateComponent from "../../../components/Date/Date.component";
import { useDispatch, useSelector } from "react-redux";
import TextFieldComponent from "../../../components/TextField/textField.Component";
import { filterByRefType, findLabelById } from "../../../utils/refDataFilters/refDataFilters";
export const DatesContent = () => {
    var _a;
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch();
    const policyEffectiveDate = useSelector((state) => state.datesInfo.dateInfoData.policyEffectiveDate);
    const policyExpirationDate = useSelector((state) => state.datesInfo.dateInfoData.policyExpirationDate);
    const reinsuranceEffectiveDate = useSelector((state) => state.datesInfo.dateInfoData.reinsuranceEffectiveDate);
    const reinsuranceExpirationDate = useSelector((state) => state.datesInfo.dateInfoData.reinsuranceExpirationDate);
    const policyType = useSelector((state) => { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.datesInfo) === null || _a === void 0 ? void 0 : _a.dateInfoData) === null || _b === void 0 ? void 0 : _b.policyType; });
    const policyOptions = filterByRefType("PolicyType");
    const handlePolicyEffectiveDateChange = (value) => {
        dispatch(setPolicyEffectiveDate(value));
    };
    const handlePolicyExpirationDateChange = (value) => {
        dispatch(setPolicyExpirationDate(value));
    };
    const handleReinsuranceEffectiveDateChange = (value) => {
        dispatch(setReinsuranceEffectiveDate(value));
    };
    const handleReinsuranceExpirationDateChange = (value) => {
        dispatch(setReinsuranceExpirationDate(value));
    };
    const handlePolicyTypeChange = (value) => {
        dispatch(setPolicyType(value));
    };
    let retrivedSubmissionData = useSelector((state) => state.retrivedSubmissionDetils.retrivedSubmissionData.submissionPayload);
    const isLinkedPage = useSelector((state) => state.isLinked.isLinkedPage);
    const [dataMapped, setdataMapped] = useState(false);
    const retrivedeffectiveDate = retrivedSubmissionData.propertySubmission.effectiveDate.split('T')[0];
    const retrivedreinsuranceEffectiveDate = retrivedSubmissionData.propertySubmission.reinsuranceEffectiveDate.split('T')[0];
    const retrivedexpirationDate = retrivedSubmissionData.propertySubmission.expirationDate.split('T')[0];
    const retrivedreinsuranceExpirationDate = retrivedSubmissionData.propertySubmission.reinsuranceExpirationDate.split('T')[0];
    const retrivedpolicyType = (_a = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission) === null || _a === void 0 ? void 0 : _a.policyType.trim();
    const mapRetrivedSubmission = () => {
        if (retrivedeffectiveDate) {
            dispatch(setPolicyEffectiveDate(retrivedeffectiveDate));
        }
        if (retrivedreinsuranceEffectiveDate) {
            dispatch(setReinsuranceEffectiveDate(retrivedreinsuranceEffectiveDate));
        }
        if (retrivedexpirationDate) {
            dispatch(setPolicyExpirationDate(retrivedexpirationDate));
        }
        if (retrivedreinsuranceExpirationDate) {
            dispatch(setReinsuranceExpirationDate(retrivedreinsuranceExpirationDate));
        }
        if (retrivedpolicyType) {
            const retrivedpolicyTypeLabel = findLabelById(policyOptions, retrivedpolicyType);
            retrivedpolicyTypeLabel && dispatch(setPolicyType(retrivedpolicyTypeLabel));
            retrivedpolicyType && dispatch(setPolicyTypeId(retrivedpolicyType));
        }
    };
    useEffect(() => {
        if (retrivedSubmissionData.submissionId > 0 && retrivedeffectiveDate) {
            dispatch(setPolicyEffectiveDate(retrivedeffectiveDate));
        }
    }, [retrivedeffectiveDate]);
    useEffect(() => {
        if (retrivedSubmissionData.submissionId > 0 && retrivedreinsuranceEffectiveDate) {
            dispatch(setReinsuranceEffectiveDate(retrivedreinsuranceEffectiveDate));
        }
    }, [retrivedreinsuranceEffectiveDate]);
    useEffect(() => {
        if (retrivedSubmissionData.submissionId > 0 && retrivedexpirationDate) {
            dispatch(setPolicyExpirationDate(retrivedexpirationDate));
        }
    }, [retrivedexpirationDate]);
    useEffect(() => {
        if (retrivedSubmissionData.submissionId > 0 && retrivedreinsuranceExpirationDate) {
            dispatch(setReinsuranceExpirationDate(retrivedreinsuranceExpirationDate));
        }
    }, [retrivedreinsuranceExpirationDate]);
    useEffect(() => {
        if (retrivedpolicyType) {
            retrivedpolicyType && dispatch(setPolicyTypeId(retrivedpolicyType.trim()));
            const retrivedpolicyTypeLabel = findLabelById(policyOptions, retrivedpolicyType);
            retrivedpolicyTypeLabel && dispatch(setPolicyType(retrivedpolicyTypeLabel));
        }
    }, [retrivedpolicyType]);
    return (_jsxs(BoxStyled, Object.assign({ sx: {
            padding: '20px 0px 20px 0px', color: '#00003f'
        } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row', height: '24px', paddingBottom: '32px' } }, { children: _jsx(TypographyAtom, Object.assign({ sx: {
                        fontSize: '18px',
                    }, variant: "h4" }, { children: "Dates" })) })), !isCollapsed &&
                _jsxs(_Fragment, { children: [_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexWrap: 'wrap' } }, { children: [_jsx(DateComponent, { value: policyEffectiveDate, label: 'Policy Effective Date', dataArrDropdown: [], isRequired: true, handleDateChange: handlePolicyEffectiveDateChange, id: 'policyEffectiveDate', labelId: "policyEffectiveDate-label", isDisabled: true, width: '300px' }), _jsx(DateComponent, { value: policyExpirationDate, label: 'Policy Expiration Date', dataArrDropdown: [], isRequired: true, handleDateChange: handlePolicyExpirationDateChange, id: 'policyExpirationDate', labelId: "policyExpirationDate", isDisabled: true, width: '300px' }), _jsx(TextFieldComponent, { value: policyType, label: 'Policy Type(Primary or Excess)', dataArrDropdown: policyOptions, isRequired: true, handleSelectChange: handlePolicyTypeChange, id: 'policyType', labelId: "policyType", width: '300px', isDisabled: true })] })), _jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexWrap: 'wrap' } }, { children: [_jsx(DateComponent, { value: reinsuranceEffectiveDate, label: 'Reinsurance Effective Date', dataArrDropdown: [], isRequired: true, handleDateChange: handleReinsuranceEffectiveDateChange, id: 'reinsuranceEffectiveDate', labelId: "reinsuranceEffectiveDate", isDisabled: true, width: '300px' }), _jsx(DateComponent, { value: reinsuranceExpirationDate, label: 'Reinsurance Expiration Date', dataArrDropdown: [], isRequired: true, handleDateChange: handleReinsuranceExpirationDateChange, id: 'reinsuranceExpirationDate', labelId: "reinsuranceExpirationDate", isDisabled: true, width: '300px' })] }))] })] })));
};
