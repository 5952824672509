import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SubmissionStatusEnum } from "../../utils/enum/SubmissionStatusEnum";
import { APIUrlTypeEnum, checkLoginUserUWOrSuperUser, decryptUserProfileDetails, getAPIBaseUrl, getLoginUserId, getLoginUserRole } from "../../utils/CommonUtilties";
import { AppRoutesConstants } from "../../utils/constants/app_routes_data";
import { GET_Method, POST_Method, headers } from "../../components/customhooks/useAxios";
import { clearRetrivedSubmissionDetails, setRetrivedSubmissionDetails } from "../../store/slices/retrivedSubmissionReducer";
import { setIsLinkedPage } from "../../store/slices/isLinkedPageReducer";
import { setSubmissionId, setSubmissionStatus } from "../../store/slices/submissionIdReducer";
import { storeUWRuleMessagesAndPricing } from "../../store/slices/coherentQuoteDetailsReducer";
import { setBusinessOverView } from "../../store/slices/businessOverviewReducer";
import { BoxAtom, CircularProgressAtom } from "@epam/reactjs-common";
import BoxStyled from "../../@atoms/layout/box/index.atom";
import ConfirmationPopUp from "../../pages/submisson_result/ConfirmationPopUp.page";
import { RoleCodeEnum } from "../../utils/enum/RoleCodeEnum";
import { setPolicyInfo } from "../../store/slices/policyInfoReducer";
import { setPerilsCoveredDetailsBind } from "../../store/slices/pearlsCoveredReducer";
import { setLossInformationBind } from "../../store/slices/lossInfoReducer";
import { setLayersRequestedBind } from "../../store/slices/layersRequestedReducer";
import { setCommissionDetailsBind } from "../../store/slices/commissionReducer";
import ErrorPage from "../../components/ErrorPage.Component";
export default function ViewSubmissionPage() {
    /* Variables */
    const dispatch = useDispatch();
    const locationParams = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    //const viewUserId = searchParams.get("pid"); /* From email Url - User Id*/
    const viewSubmissionId = searchParams.get("sid") || 0; /* From email Url - Submission Id */
    const submissionId = useSelector((state) => state.submissionIdDetails.submissionId);
    const loginUserId = getLoginUserId();
    const loginUserRole = getLoginUserRole();
    const isUWSuperUser = checkLoginUserUWOrSuperUser();
    const profileData = useSelector((state) => { var _a; return (_a = state.userProfileData) === null || _a === void 0 ? void 0 : _a.userProfileData; });
    //let retrivedSubmissionData = useSelector((state: RootState) => state.retrivedSubmissionDetils.retrivedSubmissionData.submissionPayload);
    const [showAlert, setshowAlert] = useState();
    const [showLoader, setshowLoader] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showError, setShowError] = useState(false);
    const [underwriterDetails, setunderwriterDetails] = useState();
    const [submissionDetails, setsubmissionDetails] = useState();
    const handleNoClick = () => {
        setShowPopUp(false);
        clear_assign_submission(submissionDetails);
    };
    const handleClosePopUp = () => {
        setShowPopUp(false);
    };
    const handleCloseError = () => { setShowError(false); };
    const handleYesClick = () => {
        var _a;
        let axiosParams = {
            url: `${getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl)}/Underwriting/Assignment`,
            methodType: "post",
            body: {
                "submissionId": viewSubmissionId,
                "submissionLOBId": submissionDetails.data.submissionPayload.submissionLOBs[0].submissionLOBId,
                "submissionLOBReferralId": (_a = submissionDetails.data.submissionPayload.submissionLOBs[0].submissionLOBReferral) === null || _a === void 0 ? void 0 : _a.submissionLOBReferralId,
                "overrideAndClaim": true,
                "lastChgByUserId": loginUserId,
                "underWriterId": loginUserId
            },
            headers: null
        };
        POST_Method(axiosParams).then((response1) => {
            clear_assign_submission(submissionDetails);
        }).catch((err) => {
            console.log(err);
            return _jsx(ErrorPage, { message: err });
        });
    };
    useEffect(() => {
        let acessToken = localStorage.getItem('accesstoken');
        if (acessToken) {
            // #region
            const api_URL = `${getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl)}/Submissions/${viewSubmissionId}?personId=${loginUserId}`;
            const axiosParams = {
                url: api_URL,
                methodType: "get",
                headers: headers
            };
            GET_Method(api_URL, axiosParams).then((response) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                var _underwriterId = null;
                setsubmissionDetails(response);
                if (((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.submissionPayload.submissionLOBs.length) > 0) {
                    _underwriterId = (_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.submissionPayload.submissionLOBs[0].submissionLOBReferral) === null || _c === void 0 ? void 0 : _c.underWriterId;
                }
                if (_underwriterId === null && isUWSuperUser && ((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.submissionPayload.submissionStatus) == SubmissionStatusEnum.REFERRED) {
                    /* Assign underwriter to this submission */
                    let axiosParams = {
                        url: `${getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl)}/Underwriting/Assignment`,
                        methodType: "post",
                        body: {
                            "submissionId": viewSubmissionId,
                            "submissionLOBId": (_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.submissionPayload.submissionLOBs[0].submissionLOBId,
                            "submissionLOBReferralId": (_g = (_f = response === null || response === void 0 ? void 0 : response.data) === null || _f === void 0 ? void 0 : _f.submissionPayload.submissionLOBs[0].submissionLOBReferral) === null || _g === void 0 ? void 0 : _g.submissionLOBReferralId,
                            "overrideAndClaim": true,
                            "lastChgByUserId": loginUserId,
                            "underWriterId": loginUserId
                        },
                        headers: null
                    };
                    POST_Method(axiosParams).then((response1) => {
                        console.log("Submission has been assigned successfully.");
                    }).catch((err) => {
                        console.log("Error Occured :" + err);
                        return _jsx(ErrorPage, { message: err });
                    });
                    clear_assign_submission(response);
                }
                else if (_underwriterId != null && _underwriterId != loginUserId && isUWSuperUser && ((_h = response === null || response === void 0 ? void 0 : response.data) === null || _h === void 0 ? void 0 : _h.submissionPayload.submissionStatus) == SubmissionStatusEnum.REFERRED) {
                    /* Show popup for re-assign */
                    const api_URL = `${getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl)}/UserProfile/User/${_underwriterId}?isUWRequest=true`;
                    setShowPopUp(true);
                    /* START - Retrive under writer details */
                    let axiosParams1 = {
                        url: api_URL,
                        methodType: "get",
                        headers: null
                    };
                    GET_Method(api_URL, axiosParams1).then((uwResponse) => {
                        var uwDetails = decryptUserProfileDetails(uwResponse.data);
                        //firstName lastName
                        setunderwriterDetails(uwDetails === null || uwDetails === void 0 ? void 0 : uwDetails.individual);
                    });
                    /* END - Retrive under writer details */
                }
                else {
                    clear_assign_submission(response);
                }
            }).catch((error) => {
                console.log("View Submission Throwing error", error);
                return _jsx(ErrorPage, { message: error });
            });
            // #endregion
            // }
            //else {
            //  navigate(AppRoutesConstants.AccessDenied);
            //}
        }
        else {
            //navigate("/login")   
        }
    }, [viewSubmissionId, localStorage.getItem('accesstoken')]);
    const clear_assign_submission = (response) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        dispatch(clearRetrivedSubmissionDetails());
        dispatch(setIsLinkedPage(true));
        dispatch(setRetrivedSubmissionDetails((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.submissionPayload));
        dispatch(setSubmissionId(parseInt(viewSubmissionId)));
        const _tempstatus = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.submissionPayload.submissionStatus;
        dispatch(storeUWRuleMessagesAndPricing({
            coherentRuleMessages: (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.coherentRuleMessages,
            coherentPricing: (_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.coherentPricing,
            submissionStatus: _tempstatus
        }));
        //console.log("View Submission: ", _tempstatus)
        dispatch(setSubmissionStatus(_tempstatus));
        dispatch(setBusinessOverView((_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.submissionPayload));
        dispatch(setPolicyInfo((_f = response === null || response === void 0 ? void 0 : response.data) === null || _f === void 0 ? void 0 : _f.submissionPayload));
        dispatch(setPerilsCoveredDetailsBind((_g = response === null || response === void 0 ? void 0 : response.data) === null || _g === void 0 ? void 0 : _g.submissionPayload));
        dispatch(setLossInformationBind((_h = response === null || response === void 0 ? void 0 : response.data) === null || _h === void 0 ? void 0 : _h.submissionPayload));
        dispatch(setLayersRequestedBind((_j = response === null || response === void 0 ? void 0 : response.data) === null || _j === void 0 ? void 0 : _j.submissionPayload));
        dispatch(setCommissionDetailsBind((_k = response === null || response === void 0 ? void 0 : response.data) === null || _k === void 0 ? void 0 : _k.submissionPayload));
        if (_tempstatus === SubmissionStatusEnum.SUBJECTTOREFERRAL) {
            navigate(AppRoutesConstants.SubmissionReferred + "" + locationParams.search);
        }
        else if (_tempstatus === SubmissionStatusEnum.QUOTED) {
            navigate(AppRoutesConstants.SubmissionReferred + "" + locationParams.search);
        }
        else if (_tempstatus === SubmissionStatusEnum.REFERRED) {
            if (((_l = profileData === null || profileData === void 0 ? void 0 : profileData.individual) === null || _l === void 0 ? void 0 : _l.roleCode) === RoleCodeEnum.Underwriter)
                navigate(AppRoutesConstants.UWReferral + "" + locationParams.search);
            else
                navigate(AppRoutesConstants.SubmissionReferred + "" + locationParams.search);
        }
        else if (_tempstatus === SubmissionStatusEnum.DECLINED
            && (loginUserRole == RoleCodeEnum.Broker || loginUserRole == RoleCodeEnum.Client ||
                loginUserRole == RoleCodeEnum.SuperUser)) {
            navigate(AppRoutesConstants.SubmissionResult + "" + locationParams.search);
        }
        else if ((_tempstatus === SubmissionStatusEnum.APPROVED || _tempstatus === SubmissionStatusEnum.DECLINED) && loginUserRole == RoleCodeEnum.Underwriter) {
            navigate(AppRoutesConstants.UWReferral + "" + locationParams.search);
        }
        else {
            navigate(AppRoutesConstants.SubmissionResult + "" + locationParams.search);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(BoxStyled, Object.assign({ className: "comments-wrapper", style: { margin: "1%" } }, { children: showAlert })), _jsxs(BoxAtom, Object.assign({ style: { textAlign: "center" } }, { children: [_jsx("br", {}), _jsx("strong", { children: "  Validating Submission" }), _jsx("br", {}), _jsx(CircularProgressAtom, {})] })), showPopUp &&
                _jsx(ConfirmationPopUp, { dateTime: new Date().toLocaleString(), handleYesClick: handleYesClick, handleNoClick: handleNoClick, onClose: () => handleClosePopUp(), onGoBack: () => navigate(AppRoutesConstants.SubmissionResult + "" + locationParams.search), msg: "The referral is already assigned to " + ((underwriterDetails === null || underwriterDetails === void 0 ? void 0 : underwriterDetails.firstName) + " " + (underwriterDetails === null || underwriterDetails === void 0 ? void 0 : underwriterDetails.lastName)) + ". Would you like to reassign it to yourself?", subMsg: "Do you want to proceed?", title: "Reassign " })] }));
}
