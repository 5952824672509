import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import '../../assets/styles/custom-aggrid.css';
import { headers, useAxios } from '../../components/customhooks/useAxios';
import { useLocation, useNavigate } from 'react-router-dom';
import formatDate, { DateType } from '../../utils/date.util';
import { currencyFormat } from '../../utils/currency.util';
import { AppRoutesConstants } from '../../utils/constants/app_routes_data';
import { GridPageSizeConstantForDashboard, getAgGridWidth } from '../../utils/constants/Global.Constant';
import { useSelector } from 'react-redux';
import { APIUrlTypeEnum, getAPIBaseUrl, getLoginUserId, getLoginUserRole } from '../../utils/CommonUtilties';
import { useDispatch } from 'react-redux';
import { clearRetrivedSubmissionDetails } from '../../store/slices/retrivedSubmissionReducer';
import { setSubmissionId, setSubmissionStatus } from '../../store/slices/submissionIdReducer';
import { setIsLinkedPage } from '../../store/slices/isLinkedPageReducer';
import { storeUWRuleMessagesAndPricing } from '../../store/slices/coherentQuoteDetailsReducer';
import { SubmissionStatusEnum } from '../../utils/enum/SubmissionStatusEnum';
export default function InforceContractsComponent(props) {
    /* START _ Hooks */
    const locationParams = useLocation();
    const navigate = useNavigate();
    const [useProfileId, setUserProfileId] = useState(getLoginUserId());
    const [roleCode, setroleCode] = useState(getLoginUserRole());
    const gridRef = useRef(null);
    const isSideNavOpen = useSelector((state) => state.sideNavOpen.isSideNavOpen);
    const [gridWidth, setgridWidth] = useState("474px");
    const dispatch = useDispatch();
    /* END _ Hooks */
    /* Variables */
    const submission_API_URL = getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl);
    const redirectComponent = (params) => {
        console.log("params.data: ", params.data);
        let _sId = params.data.submissionId;
        if (_sId === undefined)
            _sId = 0;
        dispatch(clearRetrivedSubmissionDetails());
        dispatch(setSubmissionId(_sId));
        dispatch(setIsLinkedPage(true));
        dispatch(setSubmissionStatus(params.data.submissionStatus));
        dispatch(storeUWRuleMessagesAndPricing({
            coherentRuleMessages: params.data.coherentRuleMessages,
            coherentPricing: params.data.coherentPricing,
            submissionStatus: params.data.submissionStatus
        }));
        if (params.data.submissionStatus === SubmissionStatusEnum.BOUNDED) {
            navigate(AppRoutesConstants.SubmissionBound + "" + locationParams.search);
            return;
        }
    };
    /* START - Columns */
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        {
            headerName: "Named Insured",
            field: "insuredName",
            // minWidth: "200",
            width: props.Sortable ? "238px" : "250px",
            cellStyle: { borderRight: '1px solid lightgray' },
            sortable: props.Sortable,
            cellRenderer: function (params) {
                return _jsx("a", Object.assign({ onClick: () => redirectComponent(params) }, { children: params.data.insuredName }));
            },
            //sort: "asc"
        },
        {
            headerName: "Policy Number", field: "clientPolicyNumber", width: "135px",
            cellStyle: { borderRight: '1px solid lightgray' },
            cellRenderer: function (params) {
                return params.data.propertySubmissionDto.clientPolicyNumber;
            }
        },
        {
            headerName: "Paper Company", field: "paperCompany", width: "90px",
            cellStyle: { borderRight: '1px solid lightgray' },
            cellRenderer: function (params) {
                return params.data.propertySubmissionDto.paperCompany;
            }
        },
        {
            headerName: "Contract ID", field: "", width: "70px",
            cellStyle: { borderRight: '1px solid lightgray' },
            cellRenderer: function (params) {
                return params.data.uwsContractNbr;
            }
        },
        {
            headerName: "Reinsurance Effective Date", field: "reinsuranceEffectiveDate", width: "80px", wrapText: true,
            cellStyle: { borderRight: '1px solid lightgray' },
            cellRenderer: function (params) {
                return formatDate(params.data.propertySubmissionDto.reinsuranceEffectiveDate, DateType.Date);
            }
        },
        {
            headerName: "Reinsurance Expiration Date", field: "reinsuranceExpirationDate", width: "80px", wrapText: true,
            cellStyle: { borderRight: '1px solid lightgray' },
            cellRenderer: function (params) {
                return formatDate(params.data.propertySubmissionDto.reinsuranceExpirationDate, DateType.Date);
            }
        },
        {
            headerName: "Bound Premium", field: "annualGrossPremium", width: "80px",
            cellStyle: { borderRight: '1px solid lightgray' },
            cellRenderer: function (params) {
                return currencyFormat(params.data.propertySubmissionDto.annualGrossPremium);
            }
        },
    ]);
    /* END - Columns */
    /* START - API Calls */
    const axiosParams = {
        url: `${submission_API_URL}/Submissions/AllInforceContract?PersonId=${useProfileId}&RoleCode=${roleCode}&PageNumber=${1}&PageSize=${GridPageSizeConstantForDashboard}`,
        methodType: "get",
        headers: headers
    };
    var { response, error, loading } = useAxios(axiosParams);
    const [responsedata, setresponsedata] = useState();
    useEffect(() => {
        var _a;
        if (response) {
            setresponsedata(response);
        }
        else {
            //setresponsedata
        }
        (_a = gridRef.current) === null || _a === void 0 ? void 0 : _a.api.showNoRowsOverlay();
        //console.log("InforceContracts - response: ", response)
    }, [response]);
    /* END - API Calls */
    const gridOptions = {
        // set background colour on every row, this is probably bad, should be using CSS classes
        //rowStyle: { background: 'black' },
        // set background colour on even rows again, this looks bad, should be using CSS classes
        getRowStyle: (params) => {
            if (params.node.rowIndex % 2 != 0) {
                return { background: '#F5F5F5' };
            }
        },
        // other grid options ...
    };
    useEffect(() => {
        getAgGridWidth(locationParams, isSideNavOpen, setgridWidth);
        //console.log("isSideNavOpen -: ",isSideNavOpen)
    }, [isSideNavOpen]);
    /* END - Functions */
    return (_jsx(_Fragment, { children: (responsedata === null || responsedata === void 0 ? void 0 : responsedata.status) === 200 ?
            _jsx("div", Object.assign({ className: "ag-theme-quartz" // applying the grid theme
                , style: { height: 432 } }, { children: _jsx(AgGridReact, { defaultColDef: {
                        resizable: false,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                        flex: 1,
                        minWidth: 147,
                        suppressMovable: true,
                    }, rowData: responsedata === null || responsedata === void 0 ? void 0 : responsedata.submissions, columnDefs: colDefs, overlayNoRowsTemplate: responsedata === null || responsedata === void 0 ? void 0 : responsedata.message }) })) : _jsx(_Fragment, { children: responsedata === null || responsedata === void 0 ? void 0 : responsedata.message }) }));
}
