import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridAtom, GridItemAtom, TypographyAtom } from "@epam/reactjs-common";
import { useEffect, useState } from "react";
import LeftarrowIcon from '../../assets/imgs/leftarrow-24.png';
import RightarrowIcon from '../../assets/imgs/rightarrow-24.png';
import { Style_LightBlueColor } from "../../components/global.styles/Global.Styles";
export default function PaginationComponent(props) {
    const [currentpage, setcurrentpage] = useState(1);
    const [displayrecordscount, setdisplayrecordscount] = useState(20);
    const [currentRecordsCount, setCurrentRecordsCount] = useState(props.isReferralComp ? 15 : 20);
    const paginationStyle = {};
    // Check prop conditions on `isReferralComp` to set display count
    useEffect(() => {
        if (props.isReferralComp) {
            setCurrentRecordsCount(props.CurrentPagesCount);
        }
        else {
            setCurrentRecordsCount(props.CurrentRecordsCount);
        }
    }, [props.isReferralComp, props.CurrentPagesCount, props.CurrentRecordsCount]);
    return (_jsx("div", Object.assign({ id: "gridpagination", className: "gridpagination", style: { fontSize: "10px", marginTop: "15px" } }, { children: _jsxs(GridAtom, Object.assign({ alignItems: "flex-start", className: "dashboard-createnewsubmission" }, { children: [_jsx(GridItemAtom, { children: _jsx(TypographyAtom, {}) }), _jsx(GridItemAtom, { children: _jsxs("div", { children: [_jsx("span", { children: " " }), _jsxs("span", Object.assign({ onClick: props.previousPage }, { children: [" ", _jsx("img", { src: LeftarrowIcon, width: "10%", style: { marginBottom: "-2px", marginRight: "2px" } }), " Page"] })), _jsxs("span", { children: [" ", _jsx("span", Object.assign({ style: { backgroundColor: Style_LightBlueColor, padding: "1% 2% 1% 2%", color: "#ffff" } }, { children: props.CurrentPage })), "  of ", props.TotalPagesCount, " "] }), _jsx("img", { onClick: props.nextPage, src: RightarrowIcon, width: "10%", style: { marginBottom: "-2px", marginLeft: "2px" } })] }) })] })) })));
}
PaginationComponent.defaultProps = {
    CurrentRecordsCount: 20,
    TotalRecordsCount: 0,
    CurrentPage: 1,
    TotalPagesCount: 1,
};
