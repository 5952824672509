import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputAdornmentAtom, TypographyAtom } from '@epam/reactjs-common';
import BoxStyled from '../../@atoms/layout/box/index.atom';
import NumberFieldOutlined from '../../@atoms/form/control/numberfield/index.atom';
import "./inputField.Component.css";
import TextFieldOutlined from '../../@atoms/form/control/textfield/index.atom';
const formatNumberWithCommas = (num) => {
    return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export default function InputFieldComponent(props) {
    const { id, isDecimalAllowed, outlineClass, label, width, isRequired, onChange, onBlur, placeholder, customInpStyle = {}, sx = {}, disabled, value, isCurrency = false, currencySymbol, isPercentage } = props;
    const handleChange = (event) => {
        let val = event.target.value.replace(/,/g, '');
        const regexNumber = /^[0-9,]*$/;
        const regexDecimal = /^\d*(?:\.\d*)?$/;
        const regex = isDecimalAllowed ? regexDecimal : regexNumber;
        if (regex.test(val)) {
            val = val.replace(/^0*(?!0)(\d+)/, '$1');
            const numericValue = val === '' ? '0' : val;
            let eventObj = { target: { value: numericValue } };
            onChange(Object.assign({}, eventObj));
        }
    };
    return (_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignSelf: 'center', width: width ? width : '300px' } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { fontSize: '12px', mb: 1 } }, { children: _jsxs(BoxStyled, Object.assign({ className: 'input-field-label', sx: { display: 'flex', flexDirection: 'row' } }, { children: [_jsx(TypographyAtom, Object.assign({ className: 'label-text', sx: {
                                fontSize: '12px', color: '#303740'
                            }, 
                            // style={{ fontSize: "12px", alignContent: "center", fontWeight: "bold", color: "rgba(0,0,0,0.6)" }}
                            component: "p" }, { children: label })), isRequired && (_jsx(TypographyAtom, Object.assign({ sx: {
                                fontSize: '12px', color: 'red', paddingLeft: '5px'
                            }, component: "p" }, { children: "*" })))] })) })), _jsx(BoxStyled, Object.assign({ className: outlineClass }, { children: isCurrency ?
                    _jsx(TextFieldOutlined, { id: id, onChange: handleChange, onBlur: onBlur, disabled: disabled, value: isPercentage ? value : formatNumberWithCommas(value !== null && value !== void 0 ? value : ""), className: "currency-field-container", inputProps: customInpStyle, InputProps: isPercentage ?
                            { endAdornment: _jsx(InputAdornmentAtom, Object.assign({ position: "end" }, { children: currencySymbol })) }
                            : { startAdornment: _jsx(InputAdornmentAtom, Object.assign({ position: "start" }, { children: currencySymbol })) } })
                    : _jsx(NumberFieldOutlined, { id: id, placeholder: placeholder, inputProps: customInpStyle, onChange: onChange, onBlur: onBlur, sx: sx, disabled: disabled, value: value }) }))] })));
}
