import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component 
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import '../../assets/styles/custom-aggrid.css';
import { SubmissionStatusEnum } from '../../utils/enum/SubmissionStatusEnum';
import Drafticon from '../../assets/imgs/draft.svg';
import Quotedicon from '../../assets/imgs/quoted.svg';
import Subjectreferralicon from '../../assets/imgs/subjectreferral.svg';
import Declinedicon from '../../assets/imgs/declined.svg';
import BoundIcon from '../../assets/imgs/BoundIcon.svg';
import ExpiredIcon from '../../assets/icon/ExpiredIcon.svg';
import ReferralsIcondashboard from '../../assets/imgs/ReferralsIcondashboard.svg';
import { useAxios, GET_Method, headers } from '../../components/customhooks/useAxios';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import formatDate, { DateType } from '../../utils/date.util';
import { AppRoutesConstants } from '../../utils/constants/app_routes_data';
import { currencyFormat } from '../../utils/currency.util';
import { GridPageSizeConstant, getAgGridWidth } from '../../utils/constants/Global.Constant';
import { useSelector } from 'react-redux';
import PaginationComponent from '../../components/pagination/Pagination.Component';
import { setSubmissionId, setSubmissionStatus } from '../../store/slices/submissionIdReducer';
import { useDispatch } from 'react-redux';
import { storeUWRuleMessagesAndPricing } from '../../store/slices/coherentQuoteDetailsReducer';
import { setIsLinkedPage } from '../../store/slices/isLinkedPageReducer';
import { APIUrlTypeEnum, getAPIBaseUrl, getLoginUserId, getLoginUserRole } from '../../utils/CommonUtilties';
import { clearRetrivedSubmissionDetails } from '../../store/slices/retrivedSubmissionReducer';
import { SubmissionPages } from '../../utils/enum/SubmissionPage';
import LoaderComponent from '../../components/loader/loader.component';
export default function SubmissionsListComponent(props) {
    /* START - Hooks */
    const locationParams = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [useProfileId, setUserProfileId] = useState(getLoginUserId());
    const [roleCode, setroleCode] = useState(getLoginUserRole());
    const gridRef = useRef(null);
    const [isSort, setisSort] = useState(false);
    const isSideNavOpen = useSelector((state) => state.sideNavOpen.isSideNavOpen);
    const [gridWidth, setgridWidth] = useState("474px");
    const [isLoading, setLoading] = useState(false);
    const [sortType, setSortType] = useState("desc");
    const [sortColumn, setSortColumn] = useState("propertySubmissions.ReinsuranceEffectiveDate");
    const dispatch = useDispatch();
    const [toggleSort, setToggleSort] = useState(false);
    /* END - Hooks */
    /* Variables */
    const submission_API_URL = getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl);
    const Style_ColumnHeader = {
        "background-color": "#061C49",
        "color": "#FFFF !important",
        "font-size": "9px",
        "font-weight": "bold",
        "--ag-header-column-separator-display": "block",
        "--ag-header-column-separator-height": "100%",
        "--ag-header-column-separator-width": "1px",
        "--ag-header-column-separator-color": "#d3d3d378",
    };
    /*  START - Grid Columns */
    const redirectComponent = (params) => {
        console.log("params.data: ", params.data);
        let _sId = params.data.submissionId;
        if (_sId === undefined)
            _sId = 0;
        dispatch(clearRetrivedSubmissionDetails());
        dispatch(setSubmissionId(_sId));
        dispatch(setIsLinkedPage(true));
        dispatch(setSubmissionStatus(params.data.submissionStatus));
        dispatch(storeUWRuleMessagesAndPricing({
            coherentRuleMessages: params.data.coherentRuleMessages,
            coherentPricing: params.data.coherentPricing,
            submissionStatus: params.data.submissionStatus
        }));
        if (params.data.submissionStatus === SubmissionStatusEnum.SUBJECTTOREFERRAL
            || params.data.submissionStatus === SubmissionStatusEnum.REFERRED) {
            navigate(AppRoutesConstants.SubmissionReferred + "" + locationParams.search);
            return;
        }
        else if (params.data.submissionStatus === SubmissionStatusEnum.DECLINED
            || params.data.submissionStatus === SubmissionStatusEnum.QUOTED
            || params.data.submissionStatus === SubmissionStatusEnum.APPROVED) {
            navigate(AppRoutesConstants.SubmissionResult + "" + locationParams.search);
        }
        else if (params.data.submissionStatus === SubmissionStatusEnum.BOUNDED) {
            navigate(AppRoutesConstants.SubmissionBound + "" + locationParams.search);
        }
        else {
            navigate(AppRoutesConstants.SubmissionRequest + "" + locationParams.search);
        }
    };
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        {
            headerName: "Named Insured",
            field: "insuredName",
            // minWidth: "200",
            width: props.Sortable ? "238px" : "250px",
            cellStyle: { borderRight: '1px solid lightgray' },
            sortable: props.Sortable,
            cellRenderer: function (params) {
                return _jsx("a", Object.assign({ onClick: () => redirectComponent(params) }, { children: params.data.insuredName }));
            },
            // simple string comparator
            //sort: "asc"
        },
        {
            headerName: "Paper Company", field: "propertySubmissionDto.paperCompany", width: "150px",
            sortable: false,
            suppressSorting: true,
            cellStyle: { borderRight: '1px solid lightgray' },
            cellRenderer: function (params) {
                var _a;
                return (_a = params.data.propertySubmissionDto) === null || _a === void 0 ? void 0 : _a.paperCompany;
            }
        },
        {
            headerName: "Policy Effective Date",
            field: "propertySubmissionDto.effectiveDate",
            width: "120px",
            cellStyle: { borderRight: '1px solid lightgray' },
            wrapText: true,
            sortable: props.Sortable,
            hide: props.ColumnHide,
            cellRenderer: function (params) {
                var _a;
                return formatDate((_a = params.data.propertySubmissionDto) === null || _a === void 0 ? void 0 : _a.effectiveDate, DateType.Date);
            }
        },
        {
            headerName: "Reinsurance Effective Date", field: "propertySubmissionDto.reinsuranceEffectiveDate",
            width: "110px", wrapText: true,
            cellStyle: { borderRight: '1px solid lightgray' },
            sortable: props.Sortable,
            cellRenderer: function (params) {
                var _a;
                return formatDate((_a = params.data.propertySubmissionDto) === null || _a === void 0 ? void 0 : _a.reinsuranceEffectiveDate, DateType.Date);
            },
        },
        {
            headerName: "Policy Number", field: "propertySubmissionDto.clientPolicyNumber", width: "170px",
            sortable: props.Sortable,
            cellStyle: { borderRight: '1px solid lightgray' },
            hide: props.ColumnHide,
            cellRenderer: function (params) {
                var _a;
                return (_a = params.data.propertySubmissionDto) === null || _a === void 0 ? void 0 : _a.clientPolicyNumber;
            }
        },
        {
            headerName: "Quoted Premium", field: "propertySubmissionDto.annualGrossPremium", width: "30px",
            sortable: props.Sortable,
            cellStyle: { borderRight: '1px solid lightgray' },
            wrapText: false,
            cellRenderer: (params) => {
                var _a, _b;
                const lastQuotedExpiryDate = (_a = params.data.propertySubmissionDto) === null || _a === void 0 ? void 0 : _a.lastQuotedExpiryDate;
                let isExpired = false;
                if (lastQuotedExpiryDate) {
                    const expiryDate = new Date(lastQuotedExpiryDate);
                    const currentDate = new Date();
                    if (expiryDate < currentDate) {
                        isExpired = true;
                    }
                }
                if (params.data.submissionStatus.toLowerCase() === "draft"
                    || params.data.submissionStatus.toLowerCase() === SubmissionStatusEnum.DRAFT.toLowerCase()
                    || params.data.submissionStatus.toLowerCase() === SubmissionStatusEnum.SUBJECTTOREFERRAL.toLowerCase()
                    || params.data.submissionStatus.toLowerCase() === SubmissionStatusEnum.DECLINED.toLowerCase()
                    || params.data.submissionStatus.toLowerCase() === SubmissionStatusEnum.REFERRED.toLowerCase()
                    || isExpired)
                    return "";
                else
                    return currencyFormat((_b = params.data.propertySubmissionDto) === null || _b === void 0 ? void 0 : _b.annualGrossPremium);
            }
        },
        {
            headerName: "Status",
            field: "status", width: "140px", flex: 1.2,
            sortable: props.Sortable,
            cellRenderer: function (params) {
                var _a;
                return getStatusIcon(params.data.submissionStatus, (_a = params.data.propertySubmissionDto) === null || _a === void 0 ? void 0 : _a.lastQuotedExpiryDate);
            }
        },
        // {
        //     headerName: "",
        //     field: "submissionStatus", width: "50px",
        //     sortable: props.sortable,
        //     cellRenderer: function (params: any) {
        //         //console.log("params: ", params.data)
        //         return ""; //getActionIcon(params.data.submissionStatus)
        //     }
        // },
    ]);
    /*  END - Grid Columns */
    //#region  functions
    const getStatusIcon = (submissionStatus, lastQuotedExpiryDate) => {
        let icon;
        submissionStatus = submissionStatus.toLowerCase();
        if (submissionStatus === SubmissionStatusEnum.DRAFT.toLowerCase())
            icon = _jsx(Drafticon, {});
        else if ((submissionStatus === SubmissionStatusEnum.QUOTED.toLowerCase() || submissionStatus === SubmissionStatusEnum.APPROVED.toLowerCase()) && lastQuotedExpiryDate && new Date(lastQuotedExpiryDate) < new Date()) //EXPIRED
            icon = _jsx(ExpiredIcon, {});
        else if (submissionStatus === SubmissionStatusEnum.QUOTED.toLowerCase())
            icon = _jsx(Quotedicon, {});
        else if (submissionStatus === SubmissionStatusEnum.SUBJECTTOREFERRAL.toLowerCase())
            icon = _jsx(Subjectreferralicon, {});
        else if (submissionStatus === SubmissionStatusEnum.DECLINED.toLowerCase())
            icon = _jsx(Declinedicon, {});
        else if (submissionStatus === SubmissionStatusEnum.APPROVED.toLowerCase())
            icon = _jsx(Quotedicon, {});
        else if (submissionStatus === SubmissionStatusEnum.BOUNDED.toLowerCase())
            icon = _jsx(BoundIcon, {});
        else if (submissionStatus === SubmissionStatusEnum.REFERRED.toLowerCase())
            icon = _jsx(ReferralsIcondashboard, {});
        return icon;
    };
    const getActionIcon = (submissionStatus) => {
        let icon;
        submissionStatus = submissionStatus.toLowerCase();
        if (submissionStatus === SubmissionStatusEnum.DRAFT.toLowerCase())
            icon = _jsx(Drafticon, {});
        else if (submissionStatus === SubmissionStatusEnum.QUOTED.toLowerCase())
            icon = _jsx(Quotedicon, {});
        else if (submissionStatus === SubmissionStatusEnum.SUBJECTTOREFERRAL.toLowerCase())
            icon = _jsx(Subjectreferralicon, {});
        else if (submissionStatus === SubmissionStatusEnum.DECLINED.toLowerCase())
            icon = _jsx(Declinedicon, {});
        return icon;
    };
    useEffect(() => {
        getAgGridWidth(locationParams, isSideNavOpen, setgridWidth);
    }, [isSideNavOpen]);
    //#endregion
    //console.log("props?.GridPageSize; ",props?.GridPageSize);
    /* START - API Calls */
    const axiosParams = {
        url: `${submission_API_URL}/Submissions?PersonId=${useProfileId}&RoleCode=${roleCode}&PageNumber=${1}&PageSize=${props.GridPageSize}&SubmissionPage=${SubmissionPages.SubmissionDashBoardList}&ColumnName=${sortColumn}&SortType=${sortType}`,
        methodType: "get",
        headers: headers
    };
    var { response, error, loading } = useAxios(axiosParams);
    const [responsedata, setresponsedata] = useState();
    useEffect(() => {
        //console.log("window.screen.availHeight: ", window.screen.availHeight)
        //console.log("window.screen.availWidth: ", window.screen.availWidth)
        //console.log("Submission Response : ", response)
        var _a;
        if (response) {
            setresponsedata(response);
            setTotalRecordsCount(response === null || response === void 0 ? void 0 : response.totalRecords);
            setTotalPagesCount(response === null || response === void 0 ? void 0 : response.totalPages);
            //settotalrecordscount(response?)
            if ((responsedata === null || responsedata === void 0 ? void 0 : responsedata.totalRecords) > GridPageSizeConstant) {
            }
            else {
                setCurrentPage(1);
                setCurrentRecordsCount(responsedata === null || responsedata === void 0 ? void 0 : responsedata.totalRecords);
            }
            if (gridRef.current != null) {
                gridRef.current.columnApi.applyColumnState({
                    state: [{ colId: "propertySubmissionDto.reinsuranceEffectiveDate", sort: "desc" }],
                    defaultState: { sort: null },
                });
            }
            // if(gridRef.current!=null){
            //     gridRef.current.columnApi.applyColumnState({
            //       state: [{ colId: sortColumn, sort: toggleSort }],
            //       defaultState: { sort: null },
            //     });
            // }
        }
        else {
            //setresponsedata(null)
        }
        (_a = gridRef.current) === null || _a === void 0 ? void 0 : _a.api.showNoRowsOverlay();
    }, [response]);
    /* END - API Calls */
    const gridOptions = {
        // set background colour on every row, this is probably bad, should be using CSS classes
        //rowStyle: { background: 'black' },
        // set background colour on even rows again, this looks bad, should be using CSS classes
        getRowStyle: (params) => {
            if (params.node.rowIndex % 2 != 0) {
                return { background: '#F5F5F5' };
            }
        },
        // other grid options ...
    };
    const paginationNumberFormatter = useCallback((params) => {
        console.log("paginationNumberFormatter - params: ", params);
        return "" + params.value.toLocaleString() + "";
    }, []);
    /** The grid has initialised and is ready for most api calls  */
    const onGridReady = useCallback((params) => {
        //console.log("onGridReady: ", gridRef.current)
        if (gridRef.current != null) {
            gridRef.current.columnApi.applyColumnState({
                state: [{ colId: "propertySubmissionDto.reinsuranceEffectiveDate", sort: "desc" }],
                defaultState: { sort: null },
            });
        }
    }, []);
    const onFirstDataRendered = useCallback((params) => {
        //params.api.paginationGoToPage(4);
    }, []);
    const overlayNoRowsTemplate = "No records to display";
    const [CurrentRecordsCount, setCurrentRecordsCount] = useState(5); // GridPageSizeConstant
    const [TotalRecordsCount, setTotalRecordsCount] = useState(0);
    const [CurrentPage, setCurrentPage] = useState(1);
    const [TotalPagesCount, setTotalPagesCount] = useState(2);
    const previousPage = () => {
        if (CurrentPage > 1) {
            let _pageNumber = CurrentPage - 1;
            setCurrentPage((pre) => _pageNumber);
            getList(_pageNumber);
            let _currentrecords = (GridPageSizeConstant * _pageNumber);
            setCurrentRecordsCount((pre) => _currentrecords);
        }
    };
    const nextPage = () => {
        //window.alert()
        if (CurrentPage < TotalPagesCount) {
            let _pageNumber = CurrentPage + 1;
            setCurrentPage((pre) => _pageNumber);
            /* START - API Calls */
            getList(_pageNumber);
            let _currentrecords = (GridPageSizeConstant * _pageNumber);
            if (_currentrecords > TotalRecordsCount) {
                setCurrentRecordsCount(TotalRecordsCount);
            }
            else {
                setCurrentRecordsCount((pre) => _currentrecords);
            }
        }
    };
    const getList = (_pageNumber) => {
        /* START - API Calls */
        let url = `${submission_API_URL}/Submissions?PersonId=${useProfileId}&RoleCode=${roleCode}&PageNumber=${_pageNumber}&PageSize=${GridPageSizeConstant}&SubmissionPage=${SubmissionPages.SubmissionDashBoardList}&ColumnName=${sortColumn}&SortType=${sortType}`;
        const axiosParams = {
            url: url,
            methodType: "get"
        };
        GET_Method(url, axiosParams).then((res) => {
            const updatedSubmissions = res.data.submissions.map((submission) => {
                if ((submission.submissionStatus !== "RQ" && submission.submissionStatus !== "RA") && sortColumn == "propertySubmissions.AnnualGrossPremium") {
                    return Object.assign(Object.assign({}, submission), { propertySubmissionDto: Object.assign(Object.assign({}, submission.propertySubmissionDto), { annualGrossPremium: toggleSort ? 0 : 10000000000 }) });
                }
                return submission;
            });
            // Create a new object with the updated submissions array
            const updatedResponseData = Object.assign(Object.assign({}, res.data), { submissions: updatedSubmissions });
            //console.log("CurrentPage: ",_pageNumber);
            setresponsedata(updatedResponseData);
            setLoading(false);
        });
    };
    const onSortChanged = (event) => {
        let _tempSort = "";
        let _tempColumnName = "";
        setLoading(true);
        let columnWithSort = event.columnApi.getColumnState().find(col => col.sort !== null);
        // console.log("1 : Column that is sorted right now is ", columnWithSort);
        // let columnWithSort = event.columnApi.getColumnState().filter(s => s.sort !== null);
        //console.log("The sort order right now is " + columnWithSort?.sort); 
        if ((columnWithSort === null || columnWithSort === void 0 ? void 0 : columnWithSort.colId) === undefined) {
            setSortType("");
            // _tempSort="desc";  
            _tempSort = toggleSort ? "desc" : "asc";
            if (sortColumn === 'propertySubmissions.ReinsuranceEffectiveDate') {
                _tempSort = _tempSort;
                setSortColumn('propertySubmissions.ReinsuranceEffectiveDate');
                setToggleSort(!_tempSort);
            }
            else {
                _tempSort = 'desc';
                setSortColumn('propertySubmissions.ReinsuranceEffectiveDate');
                setToggleSort(true);
                // setToggleSort(!toggleSort);
            }
            _tempColumnName = "propertySubmissions.ReinsuranceEffectiveDate";
            if (gridRef.current != null) {
                gridRef.current.columnApi.applyColumnState({
                    state: [{ colId: "propertySubmissionDto.reinsuranceEffectiveDate", sort: _tempSort }],
                    defaultState: { sort: null },
                });
            }
            let columnWithSort = event.columnApi.getColumnState().find(col => col.sort !== null);
            // console.log("2: Column that is sorted right now is ", columnWithSort);     
        }
        else {
            //console.log("Column that is sorted right now is ", formatColumnIdForSort(columnWithSort?.colId));
            setSortColumn(formatColumnIdForSort(columnWithSort === null || columnWithSort === void 0 ? void 0 : columnWithSort.colId)); //Store Column
            setSortType(columnWithSort === null || columnWithSort === void 0 ? void 0 : columnWithSort.sort);
            if ((columnWithSort === null || columnWithSort === void 0 ? void 0 : columnWithSort.sort) === "desc" && (columnWithSort === null || columnWithSort === void 0 ? void 0 : columnWithSort.colId) == 'propertySubmissionDto.annualGrossPremium') {
                setToggleSort(true);
            }
            else if ((columnWithSort === null || columnWithSort === void 0 ? void 0 : columnWithSort.sort) === "asc" && (columnWithSort === null || columnWithSort === void 0 ? void 0 : columnWithSort.colId) == 'propertySubmissionDto.annualGrossPremium') {
                setToggleSort(false);
            }
            _tempColumnName = formatColumnIdForSort(columnWithSort === null || columnWithSort === void 0 ? void 0 : columnWithSort.colId);
            _tempSort = columnWithSort === null || columnWithSort === void 0 ? void 0 : columnWithSort.sort;
            //setSortColumn(columnWithSort?.colId);
            let url = `${submission_API_URL}/Submissions?PersonId=${useProfileId}&RoleCode=${roleCode}&PageNumber=${CurrentPage}&PageSize=${GridPageSizeConstant}&SubmissionPage=${SubmissionPages.SubmissionDashBoardList}&ColumnName=${_tempColumnName}&SortType=${_tempSort}`;
            const axiosParams = {
                url: url,
                methodType: "get"
            };
            GET_Method(url, axiosParams).then((res) => {
                const updatedSubmissions = res.data.submissions.map((submission) => {
                    if ((submission.submissionStatus !== "RQ" && submission.submissionStatus !== "RA") && _tempColumnName == "propertySubmissions.AnnualGrossPremium") {
                        return Object.assign(Object.assign({}, submission), { propertySubmissionDto: Object.assign(Object.assign({}, submission.propertySubmissionDto), { annualGrossPremium: _tempSort == "asc" ? 10000000000 : 0 }) });
                    }
                    return submission;
                });
                // Create a new object with the updated submissions array
                const updatedResponseData = Object.assign(Object.assign({}, res.data), { submissions: updatedSubmissions });
                setresponsedata(updatedResponseData);
                setLoading(false);
            });
        }
        /* START - API Calls */
    };
    const formatColumnIdForSort = (_sortColumnId) => {
        //let _sortColumnId =  'propertySubmission.paperCompany';
        let splitvalues = _sortColumnId.split('.');
        if (splitvalues.length === 1) {
            return _sortColumnId;
        }
        else {
            let capitalizedText = _sortColumnId.split('.').map((word, index) => {
                console.log(index);
                if (index === 0)
                    return "propertySubmissions";
                else
                    return word.charAt(0).toUpperCase() + word.slice(1);
            }).join('.');
            //console.log(capitalizedText);
            return capitalizedText;
        }
    };
    return (_jsxs(_Fragment, { children: [isLoading ? _jsx(LoaderComponent, {}) : null, (responsedata === null || responsedata === void 0 ? void 0 : responsedata.status) === 200 ?
                (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "ag-theme-quartz" // applying the grid theme
                            , style: { height: 410 } }, { children: _jsx(AgGridReact, { defaultColDef: {
                                    resizable: false,
                                    wrapHeaderText: true,
                                    autoHeaderHeight: true,
                                    flex: 1,
                                    minWidth: 100,
                                    suppressMovable: true,
                                    sortable: false
                                }, ref: gridRef, rowData: responsedata.submissions, columnDefs: colDefs, overlayNoRowsTemplate: responsedata === null || responsedata === void 0 ? void 0 : responsedata.message, onGridReady: onGridReady, onSortChanged: onSortChanged, accentedSort: false }) })), props.Sortable ?
                            _jsx(PaginationComponent, { previousPage: previousPage, nextPage: nextPage, CurrentRecordsCount: CurrentRecordsCount, TotalRecordsCount: TotalRecordsCount, CurrentPage: CurrentPage, TotalPagesCount: TotalPagesCount === null ? 1 : TotalPagesCount }) : null] })) : _jsx(_Fragment, { children: responsedata === null || responsedata === void 0 ? void 0 : responsedata.message })] }));
}
SubmissionsListComponent.defaultProps = {
    Sortable: false,
    ColumnHide: false
};
