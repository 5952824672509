import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BoxAtom, ButtonAtom, CheckboxAtom, FormControlLabelAtom, NumberFieldAtom, TextFieldAtom, TypographyAtom } from '@epam/reactjs-common';
import BoxStyled from '../../../@atoms/layout/box/index.atom';
import React, { useState, useEffect } from 'react';
import Divider from '@epam/reactjs-common/atoms/layout/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import './location.css';
import { appendDollar, appendPercentage } from '../../../utils/currency.util';
import { filterByRefType, findLabelById } from '../../../utils/refDataFilters/refDataFilters';
const LocationComponent = () => {
    var _a;
    const calculateTotalSumInsured = (building, content, bi) => {
        const num1 = typeof (building) === 'undefined' ? 0 : parseInt(building.toString());
        const num2 = typeof (content) === 'undefined' ? 0 : parseInt(content.toString());
        const num3 = typeof (bi) === 'undefined' ? 0 : parseInt(bi.toString());
        return num1 + num2 + num3;
    };
    const [locationList, setLocationList] = useState([]);
    const dispatch = useDispatch();
    //dispatch(setLoctionAddressDetails(locationList));
    const locationBuildingInfoState = useSelector((state) => state.locationAddressDetails.loctionaddressDetailsData);
    const filteredLocation = locationBuildingInfoState === null || locationBuildingInfoState === void 0 ? void 0 : locationBuildingInfoState.filter(x => x.isDeleted === false);
    // Single location load
    const [hasEffectRun, setHasEffectRun] = useState(false);
    useEffect(() => {
        if (!hasEffectRun) {
            if (filteredLocation.length === 0 || locationList.length === 0) {
                if (filteredLocation.length > 0) {
                    setLocationList(filteredLocation);
                    console.log("review locationlis", filteredLocation);
                }
                else {
                    const newLocation = { addressId: Date.now(), isKeyAddress: false, isDeleted: false };
                    setLocationList([...locationList, newLocation]);
                }
            }
            setHasEffectRun(true);
        }
    }, [hasEffectRun]);
    let ddlOccupancy = filterByRefType("OccTyCode");
    let ddlConstruction = filterByRefType("ConstrTyCode");
    const ddlStateProvince = filterByRefType("StateProvince");
    const countryOptions = filterByRefType("Country");
    const ddlSprinklered = [
        { optionId: "0", label: '--' },
        { optionId: "1", label: 'Yes' },
        { optionId: "2", label: 'No' },
    ];
    const commaseparation = (value) => {
        if (!value && value !== 0)
            return ""; // Check for null, undefined, and NaN
        const numberValue = parseFloat(value + ""); // Parse the string value to a floating-point number
        if (isNaN(numberValue))
            return "";
        const formattedValue = numberValue.toLocaleString(undefined, {
            minimumFractionDigits: 0
        });
        return formattedValue;
    };
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: 'locationdivReview' }, { children: [_jsx("p", { children: _jsx(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row', color: '#00003f' } }, { children: _jsx(Typography, Object.assign({ variant: "title", sx: { fontSize: "18px", fontWeight: "400" } }, { children: "Location Address " })) })) }), _jsx("div", { children: (_a = filteredLocation === null || filteredLocation === void 0 ? void 0 : filteredLocation.filter(x => x.isDeleted === false)) === null || _a === void 0 ? void 0 : _a.map((item, index) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
                        return (_jsx("form", Object.assign({ style: { marginBottom: "2%", marginTop: '2%' } }, { children: _jsxs(Accordion, Object.assign({ defaultExpanded: true }, { children: [_jsx(AccordionSummary, Object.assign({ style: { backgroundColor: 'lightgrey' }, expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel2-content", id: "panel2-header" }, { children: _jsx("p", Object.assign({ style: { marginTop: '0px', marginBottom: '0px' } }, { children: _jsxs(Typography, Object.assign({ variant: "label1", sx: {
                                                    color: '#00003f', fontSize: "14px", fontWeight: "500", padding: '10px',
                                                    marginLeft: '-10px', paddingTop: '0px', paddingBottom: '1px'
                                                } }, { children: ["Address Location ", index + 1] })) })) })), _jsx("div", Object.assign({ style: { backgroundColor: 'lightgrey' } }, { children: _jsxs(AccordionDetails, { children: [_jsx(FormControlLabelAtom, { style: { fontFamily: 'AvenirNextLTPro-regular', color: 'black', padding: '0px 0px 20px 0px' }, label: "Use as Key Location ", control: _jsx(CheckboxAtom, { checked: item.isKeyAddress, name: 'isKeyAddress', value: item.isKeyAddress, sx: { outlineColor: '#989BA0' }, size: "small", disabled: true }) }), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px", marginBottom: '4px', marginTop: '-8px' } }, { children: _jsxs(BoxAtom, Object.assign({ sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", marginTop: '-15px', width: '100%' } }, { children: [_jsx("p", Object.assign({ style: { marginTop: '14px', width: "310px", fontSize: '12px' } }, { children: _jsx(TextFieldAtom, { id: 'countryReview', sx: { width: '100%', Color: 'red' }, name: "country", label: "Country", value: findLabelById(countryOptions, item.country), InputLabelProps: {
                                                                        shrink: true,
                                                                    }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '14px', width: "150px" } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', sx: { width: '100%', color: 'black' }, name: "postalCode", required: true, label: "Postal Code", value: item.postalCode, InputLabelProps: {
                                                                        shrink: true,
                                                                    }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '17px', width: "150px" } }, { children: _jsx(ButtonAtom, Object.assign({ className: "btn", variant: "contained", sx: {
                                                                        width: "185px", height: '36px', padding: "12px", marginTop: '35px',
                                                                        marginLeft: '5px'
                                                                    }, style: { fontWeight: 1000, width: "120px", fontSize: "12px", textTransform: 'none', color: '#303740', backgroundColor: '#B0B0B0', marginTop: '13px' } }, { children: "Search" })) }))] })) })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px", marginBottom: '4px', marginTop: '-8px' } }, { children: _jsxs(BoxAtom, Object.assign({ sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", marginTop: '-15px', width: '100%' } }, { children: [_jsx("p", Object.assign({ style: { marginTop: '14px', width: "150px" } }, { children: _jsx(TextFieldAtom, { id: 'cityReview', sx: { width: '100%', color: 'black' }, name: "city", required: true, label: "City", value: item.city, InputLabelProps: {
                                                                        shrink: true,
                                                                    }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '14px', width: "150px" } }, { children: _jsx(TextFieldAtom, { id: 'stateReview', sx: { width: '100%', color: 'black' }, name: "stateProvinceCode", required: true, label: "State/Province", value: (_a = ddlStateProvince === null || ddlStateProvince === void 0 ? void 0 : ddlStateProvince.find(x => x.id === item.stateProvinceCode)) === null || _a === void 0 ? void 0 : _a.label, InputLabelProps: {
                                                                        shrink: true,
                                                                    }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '14px', width: '310px' } }, { children: _jsx(TextFieldAtom, { id: 'address1review', sx: {}, name: "addressLine1", label: "Address", required: true, value: item.addressLine1, style: {}, InputLabelProps: {
                                                                        shrink: true,
                                                                    }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '14px', width: '310px' } }, { children: _jsx(TextFieldAtom, { id: 'address2review', name: "addressLine2", label: 'Address 2', value: item.addressLine2, InputLabelProps: {
                                                                        shrink: true,
                                                                    }, disabled: true }) }))] })) })), _jsx(Divider, { sx: { background: 'black', lineHeight: '10px', marginLeft: '-17px', marginRight: '17px', marginTop: '20px' } }), _jsx("br", {}), _jsxs("div", Object.assign({ style: { marginLeft: '-6px' } }, { children: [_jsx(TypographyAtom, Object.assign({ id: "buildingInfoLabel", variant: "label1", sx: { fontSize: "14px", fontWeight: "500", marginTop: '-11px', color: '#00003f' } }, { children: "Building Information" })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px", marginBottom: "2%" } }, { children: _jsxs(BoxAtom, Object.assign({ sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", width: '100%' } }, { children: [_jsx("p", Object.assign({ style: { width: '150px', margin: '0px' } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', sx: { height: '36px', marginTop: '15px' }, name: "buildings", required: true, label: "Buildings", value: appendDollar((_b = item.buildingDetails) === null || _b === void 0 ? void 0 : _b.buildings), InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '16px', width: '150px' } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', sx: { height: '36px' }, name: "content", required: true, label: "Contents", value: appendDollar((_c = item.buildingDetails) === null || _c === void 0 ? void 0 : _c.content), InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) })), _jsx(TextFieldAtom, { id: 'sumInsuredReview', sx: { width: '310px', height: '36px', marginTop: '15px' }, name: "totalSumInsured", placeholder: "$", label: "Total Sum Insured", InputLabelProps: {
                                                                            shrink: true,
                                                                        }, disabled: true, value: appendDollar(calculateTotalSumInsured(parseInt(((_d = item.buildingDetails) === null || _d === void 0 ? void 0 : _d.buildings) || 0), parseInt(((_e = item.buildingDetails) === null || _e === void 0 ? void 0 : _e.content) || 0), parseInt(((_f = item.buildingDetails) === null || _f === void 0 ? void 0 : _f.businessInterruption) || 0))) || 0, value: appendDollar(calculateTotalSumInsured((_g = item.buildingDetails) === null || _g === void 0 ? void 0 : _g.buildings, (_h = item.buildingDetails) === null || _h === void 0 ? void 0 : _h.content, (_j = item.buildingDetails) === null || _j === void 0 ? void 0 : _j.businessInterruption) || '') }), _jsx("p", Object.assign({ style: { marginTop: '15px', width: '150px' } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', name: "deductible", label: "Deductible Type", value: ((_k = item.buildingDetails) === null || _k === void 0 ? void 0 : _k.deductibleType) === "Percentage" ? "% Percentage" : "$ Amount", InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '15px', width: '150px' } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', name: "deductible", label: "Deductible", value: ((_l = item.buildingDetails) === null || _l === void 0 ? void 0 : _l.deductibleType) === "Percentage" ? appendPercentage((_m = item.buildingDetails) === null || _m === void 0 ? void 0 : _m.deductible) : appendDollar((_o = item.buildingDetails) === null || _o === void 0 ? void 0 : _o.deductible), InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) }))] })) })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px", marginBottom: "2%" } }, { children: _jsxs(BoxAtom, Object.assign({ sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", marginTop: '-8px', width: '100%' } }, { children: [_jsx("p", Object.assign({ style: { marginTop: '9px', width: "310px" } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', name: "businessInterruption", required: true, label: 'Business Interruption', value: appendDollar((_p = item.buildingDetails) === null || _p === void 0 ? void 0 : _p.businessInterruption), InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '9px', width: "150px" } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', name: "timeElement", required: true, label: 'Time Element', value: (((_q = item.buildingDetails) === null || _q === void 0 ? void 0 : _q.timeElement) === undefined) ? "12 Months" : ((_r = item.buildingDetails) === null || _r === void 0 ? void 0 : _r.timeElement) === 18 ? "18 Months" : "12 Months", InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '8px', width: "150px" } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', name: "sir", label: "SIR", value: (_s = item.buildingDetails) === null || _s === void 0 ? void 0 : _s.sir, InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) }))] })) })), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: "10px", marginBottom: "2px" } }, { children: _jsxs(BoxAtom, Object.assign({ sx: { display: "flex", flexDirection: "row", flexWrap: 'wrap', gap: "10px", marginTop: '-15px', width: '100%' } }, { children: [_jsx("p", Object.assign({ style: { marginTop: '15px', width: '310px' } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', name: "construction", required: true, label: "Construction", value: (_t = ddlConstruction === null || ddlConstruction === void 0 ? void 0 : ddlConstruction.find(x => { var _a; return x.id === ((_a = item.buildingDetails) === null || _a === void 0 ? void 0 : _a.construction); })) === null || _t === void 0 ? void 0 : _t.label, InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '15px', width: '310px' } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', name: "occupancy", required: true, label: "Occupancy", value: (_u = ddlOccupancy === null || ddlOccupancy === void 0 ? void 0 : ddlOccupancy.find(x => { var _a; return x.id === ((_a = item.buildingDetails) === null || _a === void 0 ? void 0 : _a.occupancy); })) === null || _u === void 0 ? void 0 : _u.label, InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '15px', width: '120px' } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', name: "deductible", required: true, label: "Sprinklered", value: (_v = ddlSprinklered === null || ddlSprinklered === void 0 ? void 0 : ddlSprinklered.find(x => { var _a; return x.optionId === ((_a = item.buildingDetails) === null || _a === void 0 ? void 0 : _a.sprinkled); })) === null || _v === void 0 ? void 0 : _v.label, InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '14px', width: '120px' } }, { children: _jsx(TextFieldAtom, { id: 'locationreview', style: { height: '36px' }, name: "grossArea", required: true, label: "Gross Area (Sq. ft.)", value: commaseparation((_w = item.buildingDetails) === null || _w === void 0 ? void 0 : _w.grossArea), InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '15px', width: '120px' } }, { children: _jsx(NumberFieldAtom, { id: 'locationreview', style: { height: '36px' }, name: "numberOfStories", required: true, label: "Number of Stories", value: (_x = item.buildingDetails) === null || _x === void 0 ? void 0 : _x.numberOfStories, InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) })), _jsx("p", Object.assign({ style: { marginTop: '15px', width: '120px' } }, { children: _jsx(NumberFieldAtom, { id: 'locationreview', style: { height: '36px' }, name: "builtYear", required: true, label: "Year Built", value: (_y = item.buildingDetails) === null || _y === void 0 ? void 0 : _y.builtYear, InputLabelProps: {
                                                                                shrink: true,
                                                                            }, disabled: true }) }))] })) }))] }))] }) }), item.addressId)] })) })));
                    }) })] })) }));
};
export default LocationComponent;
