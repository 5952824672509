import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import GridAtom, { GridItemAtom, } from '@epam/reactjs-common/atoms/layout/Grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonAtom, CardAtom, CardContentAtom, CardHeaderAtom } from '@epam/reactjs-common';
import { TypographyAtom } from '@epam/reactjs-common';
import SubmissionsListComponent from '../../components/submission/SubmissionsList.Component';
import submissionIcon from '../../assets/imgs/submission-icon.png'; //submission-icon
import inforceicon from '../../assets/imgs/inforce-icon.png';
import InforceContractsComponent from '../../components/inforcecontracts/InforceContracts.Component';
import { AppRoutesConstants } from '../../utils/constants/app_routes_data';
import { Style_DarkBlueSecondaryColor, Style_TextButton } from '../../components/global.styles/Global.Styles';
import { Style_WelcomeHeader } from '../dashboard/Dashboard.Styles';
import { GridPageSizeConstantForDashboard } from '../../utils/constants/Global.Constant';
import '../../assets/styles/submissionform.css';
import '../../assets/styles/topbar.css';
import { clearSubmissionId } from '../../store/slices/submissionIdReducer';
import { useDispatch, useSelector } from 'react-redux';
import { clearRetrivedSubmissionDetails } from '../../store/slices/retrivedSubmissionReducer';
import { RoleCodeEnum } from "../../utils/enum/RoleCodeEnum";
import { useClearFormData } from '../../utils/clearFormData';
import { setIsLinkedPage } from '../../store/slices/isLinkedPageReducer';
import { setIsValidated } from '../../store/slices/isValidatedReducer';
import { setLeftNav } from '../../store/slices/leftNavReducer';
export default function DashboardComponent() {
    var _a;
    /* Hooks */
    /* START - Hooks */
    const locationParams = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileData = useSelector((state) => { var _a; return (_a = state.userProfileData) === null || _a === void 0 ? void 0 : _a.userProfileData; });
    const clearformdata = useClearFormData();
    const createSubmissionClick = () => {
        dispatch(setIsLinkedPage(false));
        clearformdata();
        dispatch(setIsValidated(true));
        dispatch(clearRetrivedSubmissionDetails());
        dispatch(clearSubmissionId());
        navigate(AppRoutesConstants.SubmissionRequest + "" + locationParams.search);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(GridAtom, Object.assign({ width: "100%", alignItems: "flex-start", className: "dashboard-createnewsubmission" }, { children: [_jsx(GridItemAtom, { children: _jsx(TypographyAtom, Object.assign({ style: Style_WelcomeHeader }, { children: "Welcome back to EverTech!" })) }), _jsx(GridItemAtom, { children: ((_a = profileData === null || profileData === void 0 ? void 0 : profileData.individual) === null || _a === void 0 ? void 0 : _a.roleCode) === RoleCodeEnum.Underwriter ? _jsx(_Fragment, {}) : (_jsx(ButtonAtom, Object.assign({ variant: "outlined", color: "primary", onClick: createSubmissionClick, 
                            // startIcon={<AddCircleOutlineIcon />}
                            style: {
                                color: Style_DarkBlueSecondaryColor,
                                borderColor: Style_DarkBlueSecondaryColor,
                                fontSize: "9px",
                                fontWeight: "bold",
                                borderRadius: 0, marginRight: "20px"
                            } }, { children: "+ Create New Submission" }))) })] })), _jsxs(GridAtom, Object.assign({ width: "100%", alignItems: "flex-start" }, { children: [_jsx(GridItemAtom, Object.assign({ width: "50%" }, { children: _jsxs(CardAtom, { children: [_jsx(CardHeaderAtom, { title: _jsx(_Fragment, { children: _jsxs(GridAtom, Object.assign({ alignItems: "flex-start", className: "dashboard-createnewsubmission-nomargin" }, { children: [_jsxs(GridItemAtom, { children: [_jsx("img", { src: submissionIcon, style: { marginTop: "-12px", float: "left" } }), _jsx(TypographyAtom, Object.assign({ style: { fontSize: "15px" }, className: "title", variant: "title", noWrap: true }, { children: "My Submissions" }))] }), _jsx(GridItemAtom, Object.assign({ style: { fontSize: "15px", fontWeight: "bold" } }, { children: _jsxs("span", Object.assign({ style: { display: "flex", flexDirection: "column", textAlign: "end", marginTop: '-12%' } }, { children: [_jsx("span", { children: "... \u00A0" }), _jsx(ButtonAtom, Object.assign({ variant: "text", color: "primary", style: Style_TextButton, onClick: () => {
                                                                    navigate(AppRoutesConstants.SubmissionsListPage + "" + locationParams.search);
                                                                    dispatch(setLeftNav("Submissions"));
                                                                } }, { children: "See All Submissions" }))] })) }))] })) }) }), _jsx(CardContentAtom, { children: _jsx(SubmissionsListComponent, { Sortable: false, ColumnHide: true, GridPageSize: GridPageSizeConstantForDashboard }) })] }) })), _jsx(GridItemAtom, Object.assign({ width: "50%" }, { children: _jsxs(CardAtom, { children: [_jsx(CardHeaderAtom, { title: _jsx(_Fragment, { children: _jsxs(GridAtom, Object.assign({ alignItems: "flex-start", className: "dashboard-createnewsubmission-nomargin" }, { children: [_jsxs(GridItemAtom, { children: [_jsx("img", { src: inforceicon, style: { marginTop: "-9px", float: "left" } }), _jsx(TypographyAtom, Object.assign({ style: { fontSize: "15px" }, className: "title", variant: "title", noWrap: true }, { children: "All Inforce Contracts" }))] }), _jsx(GridItemAtom, Object.assign({ style: { fontSize: "15px", fontWeight: "bold" } }, { children: _jsxs("span", Object.assign({ style: { display: "flex", flexDirection: "column", textAlign: "end", marginTop: '-12%' } }, { children: [_jsx("span", { children: "... \u00A0" }), _jsx(ButtonAtom, Object.assign({ variant: "text", color: "primary", style: Style_TextButton, onClick: () => {
                                                                    navigate(AppRoutesConstants.InforceContractListPage + "" + locationParams.search);
                                                                    dispatch(setLeftNav("Inforce Contracts"));
                                                                } }, { children: "See All Inforce Contracts" }))] })) }))] })) }) }), _jsx(CardContentAtom, { children: _jsx(InforceContractsComponent, {}) })] }) }))] }))] }));
}
