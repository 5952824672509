export const Style_DarkBluePrimaryColor = "#061C49";
export const Style_DarkBlueSecondaryColor = "#0A3699";
export const Style_LightBlueColor = "#235CF4";
export const Style_CreateNewButton = {
    color: Style_DarkBlueSecondaryColor,
    borderColor: Style_DarkBlueSecondaryColor,
    fontSize: "9px",
    fontWeight: "bold",
    borderRadius: 0,
    marginRight: "5px"
};
export const Style_TextButton = {
    color: Style_LightBlueColor,
    borderColor: Style_LightBlueColor,
    fontSize: "7px",
    textTransform: "none",
};
