import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BoxStyled from "../../../@atoms/layout/box/index.atom";
import { ButtonAtom } from "@epam/reactjs-common";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutesConstants } from "../../../utils/constants/app_routes_data";
import { useState } from "react";
import { SubmissionFormData } from "../SubmissionForm/submissionFormData";
import { UpdateSubmissionEvent } from "../../../utils/enum/UpdateSubmissionEvent";
import { APIUrlTypeEnum, getAPIBaseUrl, getLoginUserId } from "../../../utils/CommonUtilties";
import { PUT_Method, POST_Method, GET_Method } from "../../../components/customhooks/useAxios";
import { setLeftNav } from "../../../store/slices/leftNavReducer";
import { setSubmissionDetails } from "../../../store/slices/submissionFormDetailsReducer";
import ApprovalPopUp from "./ApprovalPopup";
import SuccessPopUp from "./SuccessPopUp";
import { SubmissionStatusEnum } from "../../../utils/enum/SubmissionStatusEnum";
import DeclinedPopUp from "./DeclinedPopUp";
import { clearRetrivedSubmissionDetails, setRetrivedSubmissionDetails } from "../../../store/slices/retrivedSubmissionReducer";
import { setIsLinkedPage } from "../../../store/slices/isLinkedPageReducer";
import { setBusinessOverView } from "../../../store/slices/businessOverviewReducer";
import { setPolicyInfo } from "../../../store/slices/policyInfoReducer";
import { setPerilsCoveredDetailsBind } from "../../../store/slices/pearlsCoveredReducer";
import { setLossInformationBind } from "../../../store/slices/lossInfoReducer";
import { setLayersRequestedBind } from "../../../store/slices/layersRequestedReducer";
import { setCommissionDetailsBind } from "../../../store/slices/commissionReducer";
import { setEnableAutoSaveUWReferral } from "../../../store/slices/submissionIdReducer";
export const UWReferralButtonView = (props) => {
    var _a, _b, _c;
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const submission_API_URL = getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const locationParams = useLocation();
    const pid = getLoginUserId();
    const uwReferredDetails = useSelector((state) => state === null || state === void 0 ? void 0 : state.referredPreminumData);
    const limitVal = useSelector((state) => state.layerRequestedInfo.LayersRequestedData.limit);
    const sharesRequestedVal = useSelector((state) => state.layerRequestedInfo.LayersRequestedData.sharesRequested);
    const commissionCede = useSelector((state) => state.commissionData.commissionData.commissionCede);
    let retrivedSubmissionData = useSelector((state) => { var _a, _b; return (_b = (_a = state.retrivedSubmissionDetils) === null || _a === void 0 ? void 0 : _a.retrivedSubmissionData) === null || _b === void 0 ? void 0 : _b.submissionPayload; });
    const submissionId = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionId;
    const [showPopUp, setShowPopUp] = useState(false);
    const [showDeclinedPopUp, setShowDeclinedPopUp] = useState(false);
    //Get Redux data
    var { updateSubmissionDetailsRequest } = SubmissionFormData();
    // Extract underwriterId
    const underwriterId = (_c = (_b = (_a = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionLOBs) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.submissionLOBReferral) === null || _c === void 0 ? void 0 : _c.underWriterId;
    const retrivedIsPremiumUpdatedFlag = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.isPremiumUpdated;
    const retrivedisCalculatedFlag = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.isCalculated;
    const { isSaveDeclineVisible } = props;
    const isDisabled = pid !== underwriterId || (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionStatus) === SubmissionStatusEnum.APPROVED
        || (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionStatus) === SubmissionStatusEnum.DECLINED
        || (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionStatus) === SubmissionStatusEnum.SUBJECTTOREFERRAL
        || (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionStatus) === SubmissionStatusEnum.DRAFT
        || (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionStatus) === SubmissionStatusEnum.BOUNDED;
    const handleApproveClick = (comments) => {
        props.setLoading(true);
        handleApprovalDeclineSubmit(comments, '', SubmissionStatusEnum.APPROVED);
        setShowPopUp(false);
    };
    const handleCancelClick = () => {
        setShowPopUp(false);
    };
    const handleDeclineClick = (comments, declinationReasonCode) => {
        props.setLoading(true);
        handleApprovalDeclineSubmit(comments, declinationReasonCode, SubmissionStatusEnum.DECLINED);
        setShowDeclinedPopUp(false);
    };
    const handleDeclineCancelClick = () => {
        setShowDeclinedPopUp(false);
    };
    const approveClick = () => {
        saveSubmission();
        setShowPopUp(true);
    };
    const declinedPopUpClick = () => {
        // saveSubmission();
        setShowDeclinedPopUp(true);
    };
    const htmlEncode = (str) => {
        if (str == null || str == "" || str == undefined)
            return null;
        return str.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    };
    const saveSubmission = () => {
        // For submission
        updateSubmissionDetailsRequest.isRequestForUWCommentsUpdate = false;
        updateSubmissionDetailsRequest.updateSubmissionEvent = UpdateSubmissionEvent.UnderwriterUpdate;
        updateSubmissionDetailsRequest.updateSubmission = retrivedSubmissionData;
        //Update Internal Notes & Adjusted Type
        updateSubmissionDetailsRequest = Object.assign(Object.assign({}, updateSubmissionDetailsRequest), { updateSubmission: Object.assign(Object.assign({}, updateSubmissionDetailsRequest.updateSubmission), { propertySubmission: Object.assign(Object.assign({}, updateSubmissionDetailsRequest.updateSubmission.propertySubmission), { premAdjType: uwReferredDetails.referredPreminumData.premAdjType, premAdjValue: uwReferredDetails.referredPreminumData.premAdjValue, limit: limitVal, commissionCede: commissionCede, sharesRequested: sharesRequestedVal, isPremiumUpdated: retrivedIsPremiumUpdatedFlag ? retrivedIsPremiumUpdatedFlag : uwReferredDetails.referredPreminumData.isPremiumUpdated, isCalculated: retrivedisCalculatedFlag ? retrivedisCalculatedFlag : uwReferredDetails.referredPreminumData.isCalculated }), submissionLOBs: updateSubmissionDetailsRequest.updateSubmission.submissionLOBs.map(lob => (Object.assign(Object.assign({}, lob), { submissionLOBReferral: Object.assign(Object.assign({}, lob.submissionLOBReferral), { internalUWNotes: htmlEncode(uwReferredDetails.referredPreminumData.internalUWNotes) }) }))) }) });
        // Changes Regarding Blob Issue - 23/08/2024
        let updatedSubmissionData = JSON.parse(JSON.stringify(updateSubmissionDetailsRequest));
        updatedSubmissionData.updateSubmission.attachments = [];
        const axiosParams = {
            url: getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl) + "/Submissions/" + submissionId,
            methodType: "put",
            body: updatedSubmissionData
        };
        PUT_Method(axiosParams).then((res) => {
            dispatch(setSubmissionDetails({
                updateSubmission: res.data.submissionPayload,
                isRequestForStatusUpdate: false
            }));
            dispatch(setEnableAutoSaveUWReferral(false));
        }).catch((error) => {
            console.log("Error is " + error);
        });
    };
    const handleApprovalDeclineSubmit = (comments, declinationReasonCode, status) => {
        let requestData = {
            "SubmissionId": submissionId,
            "NewStatus": status,
            "LastChangeBy": retrivedSubmissionData.lastChgByUserId,
            "CompletionTS": new Date(Date.now()).toISOString(),
            "InternalUWNotes": htmlEncode(uwReferredDetails.referredPreminumData.internalUWNotes)
        };
        // comments field changes for approve or decline
        if (status === SubmissionStatusEnum.APPROVED) {
            //LOB_REFERRAL_CHANGES
            requestData = Object.assign(Object.assign({}, requestData), { "ApproveCommentsForBrokerClientFromUW": comments });
        }
        else {
            //LOB_REFERRAL_CHANGES
            requestData = Object.assign(Object.assign({}, requestData), { "DeclinationReasonComment": comments });
            requestData = Object.assign(Object.assign({}, requestData), { "DeclinationReasonCode": declinationReasonCode });
        }
        let axiosParams = {
            url: `${getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl)}/Underwriting/Status`,
            methodType: "post",
            body: requestData,
            headers: { 'Content-Type': 'application/json' }
        };
        POST_Method(axiosParams).then((response) => {
            if (response.status === 200 && status === SubmissionStatusEnum.APPROVED) {
                props.setLoading(false);
                setShowSuccessPopUp(true);
            }
            else if (response.status === 200 && status === SubmissionStatusEnum.DECLINED) {
                props.setLoading(false);
                getSubmissionById();
                navigate(AppRoutesConstants.UWReferral + "" + locationParams.search);
            }
        }).catch(error => {
            props.setLoading(false);
            console.log('Error while calling UnderWriter Status Update API ', error);
        });
    };
    const handleGoToHomeClick = () => {
        setShowSuccessPopUp(false);
        navigate(AppRoutesConstants.DashobardPage + locationParams.search);
        dispatch(setLeftNav("Home"));
    };
    const getSubmissionById = () => {
        const SubRetrival_API_URL = `${getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl)}/Submissions/${submissionId}?personId=${pid}`;
        const axiosParams = {
            url: SubRetrival_API_URL,
            methodType: "get"
        };
        props.setLoading(true);
        GET_Method(SubRetrival_API_URL, axiosParams).then((response) => {
            var _a, _b, _c, _d, _e, _f, _g;
            dispatch(clearRetrivedSubmissionDetails());
            dispatch(setIsLinkedPage(false));
            dispatch(setRetrivedSubmissionDetails((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.submissionPayload));
            dispatch(setBusinessOverView((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.submissionPayload));
            dispatch(setPolicyInfo((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.submissionPayload));
            dispatch(setPerilsCoveredDetailsBind((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.submissionPayload));
            dispatch(setLossInformationBind((_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.submissionPayload));
            dispatch(setLayersRequestedBind((_f = response === null || response === void 0 ? void 0 : response.data) === null || _f === void 0 ? void 0 : _f.submissionPayload));
            dispatch(setCommissionDetailsBind((_g = response === null || response === void 0 ? void 0 : response.data) === null || _g === void 0 ? void 0 : _g.submissionPayload));
            dispatch(setSubmissionDetails({
                updateSubmission: response === null || response === void 0 ? void 0 : response.data.submissionPayload,
                isRequestForStatusUpdate: false
            }));
            props.setLoading(false);
        }).catch((error) => {
            props.setLoading(false);
            console.error("Failed to fetch submission details:", error);
        });
    };
    return (_jsxs(BoxStyled, Object.assign({ className: "actionButtons", sx: { display: 'flex' } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { justifyContent: 'space-between', width: '20%' } }, { children: _jsx(ButtonAtom, Object.assign({ variant: "text", sx: { display: 'flex', fontWeight: 'Bold', color: '#0A3699' }, onClick: () => { navigate(AppRoutesConstants.DashobardPage + "" + locationParams.search); dispatch(setLeftNav("Home")); } }, { children: "GO TO HOME" })) })), _jsxs(BoxStyled, Object.assign({ className: "nextActionButtons" }, { children: [_jsx(ButtonAtom, Object.assign({ id: 'btnReferralSave', className: `save ${isDisabled ? 'disabled' : 'enabled'}`, onClick: (event) => saveSubmission(), disabled: isDisabled }, { children: "SAVE" })), _jsx(ButtonAtom, Object.assign({ className: `decline ${isSaveDeclineVisible ? 'disabled' : 'enabled'}`, disabled: isSaveDeclineVisible ? isSaveDeclineVisible : isDisabled, onClick: () => declinedPopUpClick() }, { children: "DECLINE" })), _jsx(ButtonAtom, Object.assign({ onClick: () => approveClick(), className: `approve ${isDisabled || isSaveDeclineVisible ? 'disabled' : 'enabled'}`, disabled: isSaveDeclineVisible ? isSaveDeclineVisible : isDisabled }, { children: "APPROVE" }))] })), _jsx(ApprovalPopUp, { open: showPopUp, onClose: handleCancelClick, onApprove: handleApproveClick }), _jsx(DeclinedPopUp, { open: showDeclinedPopUp, onClose: handleDeclineCancelClick, onDeclined: handleDeclineClick }), _jsx(SuccessPopUp, { open: showSuccessPopUp, onClose: () => setShowSuccessPopUp(false), onGoToHome: handleGoToHomeClick })] })));
};
