import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AppRoutesConstants } from '../utils/constants/app_routes_data';
import DashboardPage from '../pages/dashboard/dashboard.page';
import ViewSubmissionPage from '../pages/submission/ViewSubmission.Page';
import AccessDeniedPage from '../pages/access_denied/access_denied.page';
import Loader from '../@atoms/content/loader/loader.atom';
import UWReferralsDetailsPage from '../pages/referrals/UWReferralsDetails.Page';
import { getLoginUserRole } from '../utils/CommonUtilties';
import { RoleCodeEnum } from '../utils/enum/RoleCodeEnum';
const ScrollToTop = () => {
    const { pathname } = useLocation();
    //retrieveSubmissionData()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [pathname]);
    return null;
};
const AppLayout = lazy(() => import(/* webpackChunkName: "home" */ '../layout/AppLayout'));
const DemoStyledComponentPage = lazy(() => import(
/* webpackChunkName: "demo" */ '../pages/demo_styled/demo_styled.page'));
const SubmissionRequest = lazy(() => import(/* webpackChunkName: "home" */ '../pages/submission/SubmissionRequest.Page'));
const SubmissionsListPage = lazy(() => import(/* webpackChunkName: "home" */ '../pages/submission/SubmissionsList.Page'));
const SubmissionReview = lazy(() => import(/* webpackChunkName: "home" */ '../pages/submission_review/SubmissionReview.Page'));
const SubmissionResult = lazy(() => import(/* webpackChunkName: "home" */ '../pages/submisson_result/SubmissionResult.Page'));
const SubmissionBound = lazy(() => import(/* webpackChunkName: "home" */ '../pages/submisson_result/SubmissionBound.Page'));
const Referrals = lazy(() => import(/* webpackChunkName: "home" */ '../pages/referrals/Referrals.Page'));
const InforceContractListPage = lazy(() => import(/* webpackChunkName: "home" */ '../pages/inforce_contracts/InforceContractList.Page'));
const AppRoutes = () => (_jsxs(_Fragment, { children: [_jsx(ScrollToTop, {}), _jsx(Suspense, Object.assign({ fallback: _jsxs(_Fragment, { children: [_jsx(Loader, {}), " "] }) }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: AppRoutesConstants.Layout, element: _jsxs(AppLayout, { children: [_jsx(DashboardPage, {}), " "] }) }), _jsx(Route, { path: AppRoutesConstants.DashobardPage, element: _jsxs(AppLayout, { children: [_jsx(DashboardPage, {}), " "] }) }), _jsx(Route, { path: AppRoutesConstants.Demostyled, element: _jsx(DemoStyledComponentPage, {}) }), _jsx(Route, { path: AppRoutesConstants.SubmissionRequest, element: (getLoginUserRole() !== RoleCodeEnum.Underwriter) ?
                            _jsxs(AppLayout, { children: [_jsx(SubmissionRequest, {}), " "] }) :
                            _jsxs(AppLayout, { children: [_jsx(AccessDeniedPage, {}), " "] }) }), _jsx(Route, { path: AppRoutesConstants.SubmissionReview, element: (getLoginUserRole() !== RoleCodeEnum.Underwriter) ?
                            _jsxs(AppLayout, { children: [_jsx(SubmissionReview, {}), " "] }) :
                            _jsxs(AppLayout, { children: [_jsx(AccessDeniedPage, {}), " "] }) }), _jsx(Route, { path: AppRoutesConstants.SubmissionsListPage, element: (getLoginUserRole() !== RoleCodeEnum.Underwriter) ?
                            _jsxs(AppLayout, { children: [_jsx(SubmissionsListPage, {}), " "] }) :
                            _jsxs(AppLayout, { children: [_jsx(AccessDeniedPage, {}), " "] }) }), _jsx(Route, { path: AppRoutesConstants.SubmissionResult, element: (getLoginUserRole() !== RoleCodeEnum.Underwriter) ?
                            _jsxs(AppLayout, { children: [_jsx(SubmissionResult, {}), " "] }) :
                            _jsxs(AppLayout, { children: [_jsx(AccessDeniedPage, {}), " "] }) }), _jsx(Route, { path: AppRoutesConstants.SubmissionBound, element: _jsxs(AppLayout, { children: [_jsx(SubmissionBound, {}), " "] }) }), _jsx(Route, { path: AppRoutesConstants.SubmissionReferred, element: _jsxs(AppLayout, { children: [_jsx(SubmissionResult, {}), " "] }) }), _jsx(Route, { path: AppRoutesConstants.Referrals, element: (getLoginUserRole() == RoleCodeEnum.Underwriter || getLoginUserRole() == RoleCodeEnum.SuperUser) ?
                            _jsxs(AppLayout, { children: [_jsx(Referrals, {}), " "] }) : _jsxs(AppLayout, { children: [_jsx(AccessDeniedPage, {}), " "] }) }), _jsx(Route, { path: AppRoutesConstants.UWReferral, element: (getLoginUserRole() == RoleCodeEnum.Underwriter || getLoginUserRole() == RoleCodeEnum.SuperUser) ?
                            _jsxs(AppLayout, { children: [_jsx(UWReferralsDetailsPage, {}), " "] }) : _jsxs(AppLayout, { children: [_jsx(AccessDeniedPage, {}), " "] }) })
                    /* This use for Email Link */
                    , "/* This use for Email Link */", _jsx(Route, { path: AppRoutesConstants.ViewSubmission, element: _jsxs(AppLayout, { children: [_jsx(ViewSubmissionPage, {}), " "] }) }), _jsx(Route, { path: AppRoutesConstants.AccessDenied, element: _jsx(AppLayout, { children: _jsx(AccessDeniedPage, {}) }) }), _jsx(Route, { path: AppRoutesConstants.InforceContractListPage, element: (getLoginUserRole() !== RoleCodeEnum.Underwriter) ?
                            _jsxs(AppLayout, { children: [_jsx(InforceContractListPage, {}), " "] }) :
                            _jsxs(AppLayout, { children: [_jsx(AccessDeniedPage, {}), " "] }) }), _jsx(Route, { path: "*", element: _jsx(AppLayout, { children: _jsx(AccessDeniedPage, {}) }) })] }) }))] }));
export default AppRoutes;
