// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/avenir-next-lt-pro/AvenirNextLTPro-Regular.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n@font-face {\r\n    font-family: \"AvenirNextLTPro-Regular\";\r\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\r\n}\r\nbody {\r\n    font-family: \"AvenirNextLTPro-Regular\";\r\n}\r\n.evertechlogo {\r\n    margin-left: 1%;\r\n}\r\n\r\n.topbar-nav-links {\r\n    font-size: 14px;\r\n    color: '#FFFFFF';\r\n    width: 300px;\r\n    font-family: \"AvenirNextLTPro-Regular\";     \r\n}\r\n\r\n\r\n.topbar {\r\n    display: flex !important;\r\n    justify-content: space-between !important;\r\n    background-color: #061C49;\r\n\r\n}\r\n.css-18z9uwh-MuiGrid-root {\r\n    width: auto !important;\r\n    margin-right: 40px !important;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/assets/styles/topbar.css"],"names":[],"mappings":";AACA;IACI,sCAAsC;IACtC,+DAAgG;AACpG;AACA;IACI,sCAAsC;AAC1C;AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,sCAAsC;AAC1C;;;AAGA;IACI,wBAAwB;IACxB,yCAAyC;IACzC,yBAAyB;;AAE7B;AACA;IACI,sBAAsB;IACtB,6BAA6B;AACjC","sourcesContent":["\r\n@font-face {\r\n    font-family: \"AvenirNextLTPro-Regular\";\r\n    src: url(\"../../assets/fonts/avenir-next-lt-pro/AvenirNextLTPro-Regular.otf\") format(\"truetype\");\r\n}\r\nbody {\r\n    font-family: \"AvenirNextLTPro-Regular\";\r\n}\r\n.evertechlogo {\r\n    margin-left: 1%;\r\n}\r\n\r\n.topbar-nav-links {\r\n    font-size: 14px;\r\n    color: '#FFFFFF';\r\n    width: 300px;\r\n    font-family: \"AvenirNextLTPro-Regular\";     \r\n}\r\n\r\n\r\n.topbar {\r\n    display: flex !important;\r\n    justify-content: space-between !important;\r\n    background-color: #061C49;\r\n\r\n}\r\n.css-18z9uwh-MuiGrid-root {\r\n    width: auto !important;\r\n    margin-right: 40px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
