var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GET_Method, Blob_POST_Method, POST_Method } from "../components/customhooks/useAxios";
import { useSelector } from "react-redux";
import { useState } from 'react';
import { SubmissionFormData } from "../components/molecules/SubmissionForm/submissionFormData";
import { useDispatch } from "react-redux";
import { setApiError, setShowApiError } from "../store/slices/apiErrorReducer";
import { RoleCodeEnum } from "./enum/RoleCodeEnum";
import * as CryptoJS from 'crypto-js';
export const getLoginUserId = () => {
    var _a;
    let userProfileData = useSelector((state) => { var _a; return (_a = state.userProfileData) === null || _a === void 0 ? void 0 : _a.userProfileData; });
    if (userProfileData !== undefined && userProfileData !== null) {
        let loginUserId = (_a = userProfileData === null || userProfileData === void 0 ? void 0 : userProfileData.individual) === null || _a === void 0 ? void 0 : _a.id;
        return loginUserId;
    }
};
export const getUnderWUserId = (profile_API_URL, _underwriterId) => {
    let axiosParams1 = {
        url: `${profile_API_URL}/User/${_underwriterId}`,
        methodType: "get",
        headers: null
    };
    GET_Method(profile_API_URL + "/User/" + _underwriterId, axiosParams1).then((uwResponse) => {
        var _a;
        return (_a = uwResponse.data) === null || _a === void 0 ? void 0 : _a.individual.firstName;
    });
};
export const getLoginUserName = () => {
    var _a, _b;
    let userProfileData = useSelector((state) => { var _a; return (_a = state.userProfileData) === null || _a === void 0 ? void 0 : _a.userProfileData; });
    if (userProfileData !== undefined && userProfileData !== null) {
        let loginUserUserName = ((_a = userProfileData === null || userProfileData === void 0 ? void 0 : userProfileData.individual) === null || _a === void 0 ? void 0 : _a.firstName) + ' ' + ((_b = userProfileData === null || userProfileData === void 0 ? void 0 : userProfileData.individual) === null || _b === void 0 ? void 0 : _b.lastName);
        return loginUserUserName;
    }
};
export const getLoginUserRole = () => {
    var _a;
    let userProfileData = useSelector((state) => { var _a; return (_a = state.userProfileData) === null || _a === void 0 ? void 0 : _a.userProfileData; });
    if (userProfileData !== undefined && userProfileData !== null) {
        let loginUserRole = (_a = userProfileData === null || userProfileData === void 0 ? void 0 : userProfileData.individual) === null || _a === void 0 ? void 0 : _a.roleCode;
        return loginUserRole;
    }
};
export const decryptUserProfileDetails = (userProfileData) => {
    var key = CryptoJS.enc.Utf8.parse("@#Ever#24$Tech#$");
    let decryptedBytes = CryptoJS.AES.decrypt(userProfileData, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
};
export const checkLoginUserUWOrSuperUser = () => {
    var loginUserRole = getLoginUserRole();
    return loginUserRole == RoleCodeEnum.Underwriter || loginUserRole == RoleCodeEnum.SuperUser;
};
export const hideMessageAlerts = (setshowAlert) => {
    setTimeout(() => {
        setshowAlert(null);
    }, 3 * 1000);
};
export const useBlobDownload = (attatchmentsResultsList, submissionId) => {
    const submission_API_URL = process.env.REACT_APP_BASE_URL;
    let retrivedSubmissionData = useSelector((state) => { var _a, _b; return (_b = (_a = state.retrivedSubmissionDetils) === null || _a === void 0 ? void 0 : _a.retrivedSubmissionData) === null || _b === void 0 ? void 0 : _b.submissionPayload; });
    var { updateSubmissionDetailsRequest, createAttachmentPayload } = SubmissionFormData();
    const [attachmentUrl, setAttachmentUrl] = useState(null);
    const dispatch = useDispatch();
    const fetchBlobUrl = (attDetails) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        console.log("Fetching Blob URL with details:", attDetails); // Logging attDetails
        let url = getAPIBaseUrl(APIUrlTypeEnum.ProxyUrlBlobDownload) + `/Files/download/${(_a = attDetails === null || attDetails === void 0 ? void 0 : attDetails.attachmentId) !== null && _a !== void 0 ? _a : 0}`;
        const downloadBlob = {
            repoUrl: '',
            containerName: (_b = attDetails === null || attDetails === void 0 ? void 0 : attDetails.repoContainerName) !== null && _b !== void 0 ? _b : '',
            blobName: (_c = attDetails === null || attDetails === void 0 ? void 0 : attDetails.docIdInRepo) !== null && _c !== void 0 ? _c : '',
            attachmentId: (_d = attDetails === null || attDetails === void 0 ? void 0 : attDetails.attachmentId) !== null && _d !== void 0 ? _d : 0
        };
        console.log("Download Blob:", downloadBlob); // Logging downloadBlob
        const axiosParams = {
            url: url,
            methodType: 'post',
            body: downloadBlob,
        };
        try {
            const response = yield Blob_POST_Method(axiosParams);
            console.log("Response headers:", response.headers); // Logging response headers
            const contentDisposition = response.headers['content-disposition'];
            const fileName = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0] : attDetails === null || attDetails === void 0 ? void 0 : attDetails.docName;
            const cleanFileName = fileName.replace(/['"]/g, '');
            if (cleanFileName.endsWith('.pdf')) {
                // Open PDF in a new tab
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileUrl = URL.createObjectURL(blob);
                window.open(fileUrl);
            }
            else {
                // Download other types of files
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = cleanFileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
                setAttachmentUrl(blobUrl);
            }
        }
        catch (err) {
            console.error("Error downloading blob:", err); // Logging error
            dispatch(setApiError([{ message: "Unable to download the file.", code: "Blob Not Found" }]));
            dispatch(setShowApiError(true));
        }
    });
    const handleImageDownloadClick = (e, attachmentId) => {
        e.preventDefault();
        const attDetails = attatchmentsResultsList === null || attatchmentsResultsList === void 0 ? void 0 : attatchmentsResultsList.find((x) => x.attachmentId == attachmentId);
        if (!attDetails) {
            updateAttachmentDetails(attachmentId);
        }
        else if ((attDetails === null || attDetails === void 0 ? void 0 : attDetails.repoId) && (attDetails === null || attDetails === void 0 ? void 0 : attDetails.repoContainerName)) {
            fetchBlobUrl(attDetails);
        }
        else {
            updateAttachmentDetails(attachmentId);
        }
    };
    const updateAttachmentDetails = (attachmentId) => __awaiter(void 0, void 0, void 0, function* () {
        createAttachmentPayload.isRefresh = true,
            createAttachmentPayload.submissionId = submissionId;
        createAttachmentPayload.attachmentId = attachmentId;
        const attachmentDto = { attachmentDto: createAttachmentPayload };
        const axiosParams = {
            url: getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl) + "/Submissions/Attachment",
            methodType: "post",
            body: attachmentDto
        };
        POST_Method(axiosParams).then((response) => {
            var _a, _b, _c;
            if (response === null || response === void 0 ? void 0 : response.data.success) {
                const attDetails = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.attachments[0];
                if ((attDetails === null || attDetails === void 0 ? void 0 : attDetails.repoId) === undefined || (attDetails === null || attDetails === void 0 ? void 0 : attDetails.repoContainerName) === undefined) {
                    dispatch(setApiError([{ message: "Unable to download the file.", code: "Blob Not Found" }]));
                    dispatch(setShowApiError(true));
                }
                else {
                    fetchBlobUrl(attDetails);
                }
            }
            else {
                dispatch(setApiError([{ message: (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.message, code: (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.status }]));
                dispatch(setShowApiError(true));
            }
        }).catch((err) => {
            dispatch(setApiError([{ message: err === null || err === void 0 ? void 0 : err.message, code: err === null || err === void 0 ? void 0 : err.code }]));
            dispatch(setShowApiError(true));
        });
    });
    return { fetchBlobUrl, handleImageDownloadClick, attachmentUrl };
};
export const useAPIBaseUrl = (isFullUrl = true) => {
    if (isFullUrl)
        return process.env.REACT_APP_BASE_URL;
    else
        return process.env.REACT_APP_BASE_URL;
};
export const pdfBlobDownload = (repoUrl, containerName, blobName) => {
    const dispatch = useDispatch();
    const fetchBlobUrl = () => __awaiter(void 0, void 0, void 0, function* () {
        let url = getAPIBaseUrl(APIUrlTypeEnum.ProxyUrlBlobDownload) + `/Files/download/0`; // 0 for static file access
        const downloadBlob = {
            repoUrl: 'evertech',
            containerName: containerName !== null && containerName !== void 0 ? containerName : '',
            blobName: blobName !== null && blobName !== void 0 ? blobName : '',
        };
        const axiosParams = {
            url: url,
            methodType: 'post',
            body: downloadBlob,
        };
        try {
            const response = yield Blob_POST_Method(axiosParams);
            const contentDisposition = response.headers['content-disposition'];
            const fileName = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0] : downloadBlob.blobName;
            const cleanFileName = fileName.replace(/['"]/g, '');
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const fileUrl = URL.createObjectURL(blob);
            window.open(fileUrl);
            return fileUrl; // Return the file URL as a string
        }
        catch (err) {
            dispatch(setApiError([{ message: "Unable to download the file.", code: "Blob Not Found" }]));
            dispatch(setShowApiError(true));
            throw err;
        }
    });
    return { fetchBlobUrl };
};
export const getAPIBaseUrl = (requestUrlType) => {
    let baseUrl = process.env.REACT_APP_Proxy_Base_URL; //https://evertech-proxy-dev.azurewebsites.net
    let apicPath = process.env.REACT_APP_APIC_Path; //apic/evertech/v1.0
    let azureDocPath = process.env.REACT_APP_AzureDoc_Path; //apic/evertech-orch/v1.0/azuredocupload
    let googleSearchPath = process.env.REACT_APP_Google_Place_Search_Path; //apic/google-place-search
    var applicationAPIUrl = "";
    if (requestUrlType === APIUrlTypeEnum.AzureDocUrl) {
        applicationAPIUrl = baseUrl + "/" + azureDocPath;
    }
    else if (requestUrlType === APIUrlTypeEnum.GooglePlaceSearchUrl) {
        applicationAPIUrl = baseUrl + "/" + googleSearchPath;
    }
    else if (requestUrlType === APIUrlTypeEnum.ProxyUrlBlobDownload) {
        applicationAPIUrl = baseUrl;
    }
    else {
        applicationAPIUrl = baseUrl + "/" + apicPath;
    }
    return applicationAPIUrl;
};
export var APIUrlTypeEnum;
(function (APIUrlTypeEnum) {
    APIUrlTypeEnum["ProxyUrl"] = "ProxyUrl";
    APIUrlTypeEnum["AzureDocUrl"] = "AzureDocUrl";
    APIUrlTypeEnum["GooglePlaceSearchUrl"] = "GooglePlaceSearchUrl";
    APIUrlTypeEnum["ProxyUrlBlobDownload"] = "ProxyUrlBlobDownload";
})(APIUrlTypeEnum || (APIUrlTypeEnum = {}));
export const generateGUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
