import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import './dropdown.css'
import BoxStyled from '../../@atoms/layout/box/index.atom';
import { TypographyAtom } from '@epam/reactjs-common';
import TextfieldAtom from '@epam/reactjs-common/atoms/form/TextField';
const DateComponent = ({ id, label, labelId, dataArrDropdown, value, isRequired = false, handleDateChange, isDisabled = false, width }) => {
    return (_jsxs(BoxStyled, Object.assign({ sx: { width: { width }, padding: '10px' } }, { children: [_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row' } }, { children: [_jsx(TypographyAtom, Object.assign({ id: labelId, sx: {
                            fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)', paddingBottom: '10px', fontWeight: 'bold'
                        }, component: "p" }, { children: label })), isRequired && (_jsx(TypographyAtom, Object.assign({ sx: {
                            fontSize: '12px', color: 'red', paddingLeft: '5px'
                        }, component: "p" }, { children: "*" })))] })), _jsx(TextfieldAtom, { id: id, disabled: isDisabled, type: "date", InputLabelProps: {
                    shrink: true,
                }, value: value, onChange: (e) => handleDateChange(e.target.value) })] })));
};
export default DateComponent;
