import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextFieldAtom, TypographyAtom } from '@epam/reactjs-common';
import BoxStyled from '../../@atoms/layout/box/index.atom';
// import { TextareaAutosize} from '@mui/base/TextareaAutosize';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
function EmptyTextarea() {
    const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
    };
    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };
    const Textarea = styled(BaseTextareaAutosize)(({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `);
    return _jsx(_Fragment, {});
}
export default function TextFieldComponent(props) {
    const { className, width, isDisabled, label, isRequired, handleTextChange, placeholder, customInpStyle = {}, value = {}, sx = { marginTop: '-10px' }, InputLabelProps, id, multiline = false, rows, autosize = false } = props;
    return (_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: width ? width : '300px', padding: '10px' } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { fontSize: '12px' } }, { children: _jsxs(BoxStyled, Object.assign({ className: className, sx: { display: 'flex', flexDirection: 'row' } }, { children: [_jsx(TypographyAtom, Object.assign({ sx: {
                                fontSize: '12px', color: '#303740'
                            }, style: { fontSize: "12px", alignContent: "center", fontWeight: "bold", color: "rgba(0,0,0,0.6)", paddingBottom: '10px' }, component: "p" }, { children: label })), isRequired && (_jsx(TypographyAtom, Object.assign({ sx: {
                                fontSize: '12px', color: 'red', paddingLeft: '5px'
                            }, component: "p" }, { children: "*" })))] })) })), multiline === false && _jsx(TextFieldAtom, { id: id, value: value, onChange: (e) => handleTextChange(e.target.value), placeholder: placeholder, inputProps: customInpStyle, disabled: isDisabled }), multiline === true &&
                _jsx(TextFieldAtom, { id: id, multiline: true, rows: rows, defaultValue: value, placeholder: placeholder, disabled: isDisabled, inputProps: customInpStyle, onChange: (e) => handleTextChange(e.target.value) }), autosize === true &&
                _jsx(BaseTextareaAutosize, { placeholder: "Minimum 3 rows", minRows: 1 })
            // <EmptyTextarea 
            // aria-label={label}
            //   // id={id}
            //   // minRows={3} 
            //   // value={value}
            //   // sx={{width:'100%' }}
            //   // defaultValue={value}
            //   // placeholder={placeholder} 
            //   // disabled={isDisabled}
            //   // inputProps={customInpStyle}
            //   // onChange={(e) => handleTextChange(e.target.value)}
            // />
        ] })));
}
