const ClientSettingsUrl = '/client-settings';
/**
 * Provides all routes of the application.
 * The route comes with the '/'. For example: '/dashboard'.
 */
export const AppRoutesConstants = {
    Layout: '/',
    DashobardPage: '/dashboard',
    Demostyled: '/demostyled',
    SubmissionRequest: '/submissionrequest',
    SubmissionsListPage: '/submissionslist',
    SubmissionReview: "/submissionreview",
    SubmissionResult: "/submissionresult",
    SubmissionReferred: "/submissionreferred",
    ViewSubmission: "/viewsubmission",
    AccessDenied: "/AccessDenied",
    Referrals: "/referrals",
    UWReferral: "/uwReferral",
    SubmissionBound: "/submissionbound",
    InforceContractListPage: '/inforcecontractlist',
};
