import { useSelector } from "react-redux";
export const filterByRefType = (input, refData) => {
    var _a;
    const profileRefData = useSelector((state) => state.referenceData.referenceData);
    if (!refData) {
        refData = profileRefData;
    }
    return (_a = refData === null || refData === void 0 ? void 0 : refData.filter(item => item.refType === input)) === null || _a === void 0 ? void 0 : _a.map(item => ({ id: item.etCode.trim(), label: item.desc, default: item.default }));
};
export const findIdByLabel = (options, inputLabel) => {
    const option = options.find(option => option.label == inputLabel);
    return option ? option.id : null;
};
export const findLabelById = (options, inputId) => {
    const option = options.find(option => option.id == inputId);
    return option ? option.label : null;
};
