import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import BoxStyled from "../../../@atoms/layout/box/index.atom";
import { TypographyAtom } from '@epam/reactjs-common';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLob, setLobId, setPaperCompany, setPaperCompanyId, setPolicyNum } from "../../../store/slices/policyInfoReducer";
import InputFieldComponent from "../../../components/InputField/inputField.Component";
import DividerStyled from "../../../@atoms/layout/divider/index.atom";
import TextFieldComponent from "../../../components/TextField/textField.Component";
import { filterByRefType, findLabelById } from "../../../utils/refDataFilters/refDataFilters";
import { extractPaperCompanyData } from "../../../utils/profileDataFilters/paperCompanyOptionFilter";
export const PolicyInfoContent = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const lobOptions = filterByRefType("LOB");
    const policyNumOptions = [
        { id: 1, label: 'Federal' },
        { id: 2, label: 'Auto' }
    ];
    const dispatch = useDispatch();
    const paperCompanyOptions = extractPaperCompanyData();
    const lob = useSelector((state) => state.policyInfo.policyInfowData.lob);
    const paperCompany = useSelector((state) => state.policyInfo.policyInfowData.paperCompany);
    const lobIdData = useSelector((state) => { var _a; return (_a = state === null || state === void 0 ? void 0 : state.policyInfo) === null || _a === void 0 ? void 0 : _a.policyInfowData.lobId; });
    const paperCompanyId = useSelector((state) => { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.policyInfo) === null || _a === void 0 ? void 0 : _a.policyInfowData) === null || _b === void 0 ? void 0 : _b.paperCompanyId; });
    const policyNum = useSelector((state) => state.policyInfo.policyInfowData.policyNumber);
    const isLinkedPage = useSelector((state) => state.isLinked.isLinkedPage);
    let retrivedSubmissionData = useSelector((state) => state.retrivedSubmissionDetils.retrivedSubmissionData.submissionPayload);
    const [dataMapped, setdataMapped] = useState(false);
    const _propertySubmission = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission;
    const retrivedLOBType = _propertySubmission === null ? "" : _propertySubmission === null || _propertySubmission === void 0 ? void 0 : _propertySubmission.policyType;
    const retrivedPapercompany = _propertySubmission === null ? "" : _propertySubmission === null || _propertySubmission === void 0 ? void 0 : _propertySubmission.paperCompany;
    const retrivedClientPolicyNumber = _propertySubmission === null ? "" : _propertySubmission === null || _propertySubmission === void 0 ? void 0 : _propertySubmission.clientPolicyNumber;
    const paperCompanyIdData = (paperCompanyId === "" || paperCompanyId === null) ? retrivedPapercompany : paperCompanyId;
    useEffect(() => {
        const retrivedLOBLabel = findLabelById(lobOptions, lobIdData);
        //console.log("retrivedLOBLabel: ",retrivedLOBLabel)
        //console.log("retrivedLOBLabel: ",lobIdData)
        //console.log("retrivedLOBLabel: ",lobOptions)
        dispatch(setLob(retrivedLOBLabel));
    }, [lobIdData]);
    useEffect(() => {
        const paperCompanyLabel = findLabelById(paperCompanyOptions, paperCompanyId);
        paperCompanyLabel ? dispatch(setPaperCompany(paperCompanyLabel)) : dispatch(setPaperCompany(''));
        dispatch(setPaperCompanyId(paperCompanyId));
    }, [paperCompanyId]);
    const mapRetrivedSubmission = () => {
        if (retrivedLOBType) {
            const retrivedLOB = findLabelById(lobOptions, retrivedLOBType);
            retrivedLOB && dispatch(setLob(retrivedLOB));
            retrivedLOBType && dispatch(setLobId(retrivedLOBType));
        }
        if (retrivedPapercompany) {
            const retrivedPapercompanyLabel = findLabelById(paperCompanyOptions, retrivedPapercompany);
            retrivedPapercompanyLabel && dispatch(setPaperCompany(retrivedPapercompanyLabel));
            retrivedPapercompany && dispatch(setPaperCompanyId(retrivedPapercompany));
        }
        if (retrivedClientPolicyNumber) {
            dispatch(setPolicyNum(retrivedClientPolicyNumber));
        }
    };
    useEffect(() => {
        if (lobIdData) {
            const retrivedLOBLabel = findLabelById(lobOptions, lobIdData);
            retrivedLOBLabel && dispatch(setLob(retrivedLOBLabel));
        }
        if (paperCompanyIdData) {
            const retrivedpaperCompanyLabel = findLabelById(paperCompanyOptions, paperCompanyIdData);
            retrivedpaperCompanyLabel && dispatch(setPaperCompany(retrivedpaperCompanyLabel));
        }
    });
    useEffect(() => {
        if (isLinkedPage && !dataMapped && retrivedSubmissionData.submissionId != 0) {
            mapRetrivedSubmission();
            setdataMapped(true);
        }
    }, [retrivedSubmissionData.submissionId]);
    return (_jsxs(BoxStyled, Object.assign({ sx: {
            padding: '20px 0px 20px 0px', color: '#00003f'
        } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row', height: '24px', paddingBottom: '32px' } }, { children: _jsx(TypographyAtom, Object.assign({ sx: {
                        fontSize: '18px'
                    }, variant: "h4" }, { children: "Policy Information" })) })), _jsx(TypographyAtom, Object.assign({ sx: {
                    fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)', fontWeight: 'bold', paddingBottom: '10px'
                }, component: "p" }, { children: _jsx("span", {}) })), _jsx(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'row' } }, { children: _jsx(TypographyAtom, Object.assign({ sx: {
                        fontSize: '15px',
                        padding: '10px',
                        fontWeight: 800,
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        color: 'white',
                        width: '200px',
                        height: '40px',
                        textAlign: 'center'
                    }, variant: "h5" }, { children: "Property" })) })), _jsx(DividerStyled, { sx: { color: 'black' }, orientation: "horizontal" }), !isCollapsed &&
                _jsx(_Fragment, { children: _jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexWrap: 'wrap' } }, { children: [_jsx(TextFieldComponent, { value: lob, label: 'Line of Business', dataArrDropdown: lobOptions, isRequired: true, id: 'client-name', labelId: "client-name-label", isDisabled: true, width: '300px' }), _jsx(TextFieldComponent, { value: paperCompany, label: 'Paper Company', dataArrDropdown: paperCompanyOptions, isRequired: true, id: 'client-name', labelId: "client-name-label", isDisabled: true, width: '300px' }), _jsx(BoxStyled, Object.assign({ sx: { display: "flex", alignSelf: "center" } }, { children: _jsx(InputFieldComponent, { label: "Client Policy Number", isRequired: true, customInpStyle: {
                                        style: { padding: "9px 13px 9px 10px", width: "300px", backgroundColor: "#E5E5E5" }
                                    }, value: policyNum, disabled: true, width: '300px' }) }))] })) })] })));
};
