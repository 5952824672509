var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import BoxStyled from '../../../@atoms/layout/box/index.atom';
import { ButtonAtom, TypographyAtom } from '@epam/reactjs-common';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextFieldComponent from "../../../components/TextField/textField.Component";
import { appendDollar } from "../../../utils/currency.util";
import { setLayersRequestedDetails } from "../../../store/slices/layersRequestedReducer";
import { setCommissionDetails } from "../../../store/slices/commissionReducer";
import "../../referrals/referrals.css";
import { APIUrlTypeEnum, getAPIBaseUrl, getLoginUserId } from "../../../utils/CommonUtilties";
import InputFieldComponent from "../../../components/InputField/inputField.Component";
import { setUnderWriterReferredDetails } from "../../../store/slices/underWriterReferredReducer";
import { SubmissionFormData } from "../SubmissionForm/submissionFormData";
import { POST_Method } from "../../../components/customhooks/useAxios";
import { setRetrivedSubmissionDetails } from "../../../store/slices/retrivedSubmissionReducer";
import { SubmissionStatusEnum } from "../../../utils/enum/SubmissionStatusEnum";
import { setEnableAutoSaveUWReferral } from "../../../store/slices/submissionIdReducer";
export const LayersRequestedContent = ({ setLoading: setLoader, handleRecalculateVisibility: handleRecalculateVisibility, isPreminumFieldsVisible, isUWReferred, handleIsCalculatedFlag }) => {
    var _a, _b, _c;
    const submission_API_URL = process.env.REACT_APP_SUBMISSION_API_URL;
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch();
    // const [loading, setLoading] = useState(true);
    const attachVal = useSelector((state) => state.layerRequestedInfo.LayersRequestedData.attachmentPoint);
    const limitVal = useSelector((state) => state.layerRequestedInfo.LayersRequestedData.limit);
    const partOfVal = useSelector((state) => state.layerRequestedInfo.LayersRequestedData.partOf);
    const tivVal = useSelector((state) => state.layerRequestedInfo.LayersRequestedData.tiv);
    const sharesVal = useSelector((state) => state.layerRequestedInfo.LayersRequestedData.sharesRequested);
    const layerAnnualizedClientOfferAmt = useSelector((state) => state.commissionData.commissionData.layerAnnualizedClientOfferAmt);
    const cedeVal = useSelector((state) => state.commissionData.commissionData.commissionCede);
    const commissionBrokerage = useSelector((state) => { var _a, _b; return (_b = (_a = state.commissionData) === null || _a === void 0 ? void 0 : _a.commissionData) === null || _b === void 0 ? void 0 : _b.commissionBrokerage; });
    const netRetentionAndTreaty = useSelector((state) => { var _a, _b; return (_b = (_a = state.layerRequestedInfo) === null || _a === void 0 ? void 0 : _a.LayersRequestedData) === null || _b === void 0 ? void 0 : _b.layerNetRetentionAndTreaty; });
    const [showAlert, setshowAlert] = useState();
    let layerAnnualizedClientOffer = layerAnnualizedClientOfferAmt === 0 ? '' : layerAnnualizedClientOfferAmt;
    const profileId = getLoginUserId();
    let retrivedSubmissionData = useSelector((state) => { var _a, _b; return (_b = (_a = state.retrivedSubmissionDetils) === null || _a === void 0 ? void 0 : _a.retrivedSubmissionData) === null || _b === void 0 ? void 0 : _b.submissionPayload; });
    const isLinkedPage = useSelector((state) => state.isLinked.isLinkedPage);
    const underwriterId = (_c = (_b = (_a = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionLOBs) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.submissionLOBReferral) === null || _c === void 0 ? void 0 : _c.underWriterId;
    let loginUserId = getLoginUserId();
    const isAllDisabled = loginUserId !== underwriterId || (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionStatus) === SubmissionStatusEnum.APPROVED
        || (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionStatus) === SubmissionStatusEnum.DECLINED
        || (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionStatus) === SubmissionStatusEnum.SUBJECTTOREFERRAL
        || (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionStatus) === SubmissionStatusEnum.DRAFT
        || (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionStatus) === SubmissionStatusEnum.BOUNDED;
    const [dataMapped, setDataMapped] = useState(false);
    const retrivedattachmentPoint = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.attachmentPoint;
    const retrivedlimit = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.limit;
    const retrivedpartOf = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.partOf;
    const retrivedtiv = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.tiv;
    const retrivedsharesRequested = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.sharesRequested;
    const retrivedannualGrossPremium = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.annualGrossPremium;
    const retrivedLayerAnnualizedClientOfferAmt = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.layerAnnualizedClientOfferAmt;
    const retrivedCedingComm = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.commissionCede;
    const retrivedCedingBrok = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.commissionBrokerage;
    const retrivedIsCalculatedFlag = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.isCalculated;
    const retrivedIsPremiumUpdatedFlag = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.isPremiumUpdated;
    const retrivedlayerNetRetentionAndTreaty = retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.propertySubmission.layerNetRetentionAndTreaty;
    const uwReferredDetails = useSelector((state) => { var _a; return (_a = state.referredPreminumData) === null || _a === void 0 ? void 0 : _a.referredPreminumData; });
    //const [ceedingCommission, setCeedingCommission] = useState('');
    const [sharedRequest, setSharedRequest] = useState('');
    const [brokerCommission, setBrokerCommission] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [calculatedLimit, setCalculatedLimit] = useState('');
    //Get Redux data
    var { updateSubmissionDetailsRequest } = SubmissionFormData();
    let leftNav = useSelector((state) => state.sideNavOpen.isSideNavOpen);
    let messageWidth = leftNav ? '980px' : '860px';
    const handleBrokerCommissionChange = (value) => {
        dispatch(setCommissionDetails({ commissionBrokerage: value }));
    };
    const handleCeedingCommissionChange = (value) => {
        dispatch(setCommissionDetails({ commissionCede: value }));
        // If value is changed, set showErrorMessage to true and disable the premium button
        UpdateRecalculateVisibility(value, setShowErrorMessage, handleRecalculateVisibility);
        dispatch(setEnableAutoSaveUWReferral(true));
    };
    useEffect(() => {
        dispatch(setUnderWriterReferredDetails({ isCalculated: showErrorMessage }));
        handleIsCalculatedFlag = showErrorMessage;
    }, [showErrorMessage]);
    const calculateLimit = () => __awaiter(void 0, void 0, void 0, function* () {
        setCalculatedLimit('');
        let limit = sharesVal && retrivedpartOf ? (sharesVal * retrivedpartOf) / 100 : 0;
        setCalculatedLimit((limit.toString()));
        dispatch(setLayersRequestedDetails({ limit: limit }));
        return limit;
    });
    const callPricingAPI = () => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        try {
            setLoader(true);
            const calculatedLimitAmt = yield calculateLimit();
            dispatch(setEnableAutoSaveUWReferral(false));
            updateSubmissionDetailsRequest.updateSubmission = retrivedSubmissionData;
            //Update Internal Notes & Premium Adjusted Type/value
            updateSubmissionDetailsRequest = Object.assign(Object.assign({}, updateSubmissionDetailsRequest), { updateSubmission: Object.assign(Object.assign({}, updateSubmissionDetailsRequest.updateSubmission), { propertySubmission: Object.assign(Object.assign({}, updateSubmissionDetailsRequest.updateSubmission.propertySubmission), { premAdjType: uwReferredDetails.premAdjType, premAdjValue: uwReferredDetails.premAdjValue, limit: calculatedLimitAmt, sharesRequested: sharesVal, commissionBrokerage: commissionBrokerage, commissionCede: cedeVal, lastChgByUserId: profileId, isPremiumUpdated: false, isCalculated: false }) }) });
            // Changes Regarding Blob Issue - 23/08/2024
            let updatedSubmissionData = JSON.parse(JSON.stringify(updateSubmissionDetailsRequest));
            updatedSubmissionData.updateSubmission.attachments = [];
            // calling UW pricing API
            const axiosParams = {
                url: getAPIBaseUrl(APIUrlTypeEnum.ProxyUrl) + "/Underwriting/Price",
                methodType: "POST",
                body: updatedSubmissionData
            };
            const response = yield POST_Method(axiosParams);
            if (response.data.success) {
                setShowErrorMessage(false);
                dispatch(setUnderWriterReferredDetails({ isCalculated: false }));
                handleRecalculateVisibility(false);
                dispatch(setEnableAutoSaveUWReferral(false));
                dispatch(setRetrivedSubmissionDetails((_d = response === null || response === void 0 ? void 0 : response.data) === null || _d === void 0 ? void 0 : _d.submissionPayload));
                setLoader(false);
            }
            else {
                throw new Error(`Response failed; Reason: ${(_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.message}`);
            }
        }
        catch (error) {
            setLoader(false);
            console.error('Error while calling Pricing API:', error);
        }
        finally {
            setLoader(false);
        }
    });
    function formatSharesReq() {
        const number = sharesVal ? parseFloat(sharesVal.toString()) : 0;
        let formatted = number.toFixed(2);
        if (parseFloat(formatted) < 100) {
            dispatch(setLayersRequestedDetails({ sharesRequested: formatted }));
        }
    }
    function formatCedingComm() {
        const number = cedeVal ? parseFloat(cedeVal.toString()) : 0;
        let formatted = number.toFixed(2);
        if (parseFloat(formatted) < 100) {
            dispatch(setCommissionDetails({ commissionCede: formatted }));
        }
    }
    const handleSharedRequestChange = (value) => {
        dispatch(setLayersRequestedDetails({ sharesRequested: value }));
        setSharedRequest(value);
        dispatch(setEnableAutoSaveUWReferral(true));
        // If value is changed, set showErrorMessage to true and disable the premium button
        UpdateRecalculateVisibility(value, setShowErrorMessage, handleRecalculateVisibility);
    };
    useEffect(() => {
        if (isLinkedPage && !dataMapped && (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionId) != 0) {
            mapRetrivedSubmission();
            setDataMapped(true);
        }
    }, [retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionId]);
    const mapRetrivedSubmission = () => {
        if (retrivedlimit != 0) {
            dispatch(setLayersRequestedDetails({ limit: retrivedlimit }));
        }
        if (retrivedattachmentPoint) {
            dispatch(setLayersRequestedDetails({ attachmentPoint: retrivedattachmentPoint }));
        }
        if (retrivedpartOf) {
            dispatch(setLayersRequestedDetails({ partOf: retrivedpartOf }));
        }
        if (retrivedtiv) {
            dispatch(setLayersRequestedDetails({ tiv: retrivedtiv }));
        }
        if (retrivedsharesRequested) {
            dispatch(setLayersRequestedDetails({ sharesRequested: retrivedsharesRequested }));
        }
        if (retrivedLayerAnnualizedClientOfferAmt) {
            dispatch(setCommissionDetails({ layerAnnualizedClientOfferAmt: retrivedLayerAnnualizedClientOfferAmt }));
        }
        if (retrivedCedingComm) {
            dispatch(setCommissionDetails({ commissionCede: retrivedCedingComm }));
        }
        if (retrivedCedingBrok) {
            dispatch(setCommissionDetails({ commissionBrokerage: retrivedCedingBrok }));
        }
        if (retrivedlayerNetRetentionAndTreaty) {
            dispatch(setLayersRequestedDetails({ layerNetRetentionAndTreaty: retrivedlayerNetRetentionAndTreaty }));
        }
    };
    useEffect(() => {
        if (isLinkedPage && !dataMapped && (retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionId) != 0) {
            mapRetrivedSubmission();
            setDataMapped(true);
        }
    }, [retrivedSubmissionData === null || retrivedSubmissionData === void 0 ? void 0 : retrivedSubmissionData.submissionId]);
    const appendPercentage = (value) => {
        return `${value}%`; // Add the percentage sign to the value  
    };
    return (_jsxs(BoxStyled, Object.assign({ id: 'layers-requested', sx: {
            padding: '20px 0px 20px 0px', color: '#00003f'
        } }, { children: [showAlert, _jsx(BoxStyled, Object.assign({ className: "boxStyled" }, { children: _jsx(TypographyAtom, Object.assign({ sx: {
                        fontSize: '18px',
                    }, variant: "h4" }, { children: "Layers Requested" })) })), !isCollapsed &&
                _jsx(_Fragment, { children: _jsxs(BoxStyled, Object.assign({ className: "boxStyledFlex" }, { children: [_jsxs(BoxStyled, { children: [_jsxs(BoxStyled, Object.assign({ className: "boxStyledFlex" }, { children: [_jsx(TextFieldComponent, { width: "170px", label: "Limit", placeholder: "$", isRequired: true, isDisabled: true, value: appendDollar(limitVal), customInpStyle: {
                                                    style: {
                                                        padding: "9px 3px 9px 10px",
                                                        marginLeft: "0em",
                                                        backgroundColor: "#E5E5E5"
                                                    }
                                                } }), _jsx(BoxStyled, { children: _jsx(TextFieldComponent, { width: "170px", label: "Layer", placeholder: "$", isRequired: true, isDisabled: true, customInpStyle: {
                                                        style: {
                                                            padding: "9px 3px 9px 10px",
                                                            backgroundColor: "#E5E5E5"
                                                        }
                                                    }, value: appendDollar(partOfVal) }) })] })), isUWReferred && (_jsx(BoxStyled, { children: _jsx(BoxStyled, Object.assign({ sx: { pr: "0px" } }, { children: _jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', paddingLeft: '10px' } }, { children: [_jsx(BoxStyled, Object.assign({ sx: { marginRight: '10px', marginTop: '12px' } }, { children: _jsx(InputFieldComponent, { label: "Ceding Commission", isRequired: true, width: '150px', height: '70px', id: 'ceding', disabled: isAllDisabled || isPreminumFieldsVisible || retrivedIsPremiumUpdatedFlag, customInpStyle: {
                                                                style: {
                                                                    height: '3px',
                                                                    border: (!isUWReferred || isPreminumFieldsVisible || retrivedIsPremiumUpdatedFlag) ? '1px solid #80808075' : "white",
                                                                    // backgroundColor: (!isUWReferred || isPreminumFieldsVisible || retrivedIsPremiumUpdatedFlag) ? 'rgb(229, 229, 229)' : "white",
                                                                }
                                                            }, onChange: (e) => handleCeedingCommissionChange(e.target.value), isDecimalAllowed: true, value: !isUWReferred || isAllDisabled || isPreminumFieldsVisible || retrivedIsPremiumUpdatedFlag ? cedeVal !== null ? appendPercentage(cedeVal === null || cedeVal === void 0 ? void 0 : cedeVal.toString()) : appendPercentage("0") : cedeVal, isCurrency: true, currencySymbol: isUWReferred && !isAllDisabled && !isPreminumFieldsVisible && !retrivedIsPremiumUpdatedFlag ? "%" : "", isPercentage: true, handleTextChange: handleCeedingCommissionChange, onBlur: formatCedingComm }) })), _jsx(TextFieldComponent, { label: "Broker Commission", isRequired: true, width: '170px', value: appendPercentage((commissionBrokerage.toString())), isDisabled: true, customInpStyle: {
                                                            style: { backgroundColor: "#E5E5E5" }
                                                        }, handleTextChange: handleBrokerCommissionChange })] })) })) }))] }), _jsxs(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsxs(BoxStyled, Object.assign({ sx: { display: 'flex' } }, { children: [_jsx(TextFieldComponent, { label: 'Attachment Point', placeholder: "$", isRequired: true, isDisabled: true, customInpStyle: {
                                                    style: {
                                                        padding: "9px 3px 9px 10px",
                                                        marginRight: "0em",
                                                        backgroundColor: "#E5E5E5",
                                                    }
                                                }, disabled: true, value: appendDollar(attachVal), width: "210px" }), _jsx(BoxStyled, { children: _jsx(TextFieldComponent, { width: "235px", label: "TIV (sum building/contents/BI etc.)", placeholder: "$", isRequired: true, isDisabled: true, customInpStyle: {
                                                        style: { padding: "9px 3px 9px 10px", backgroundColor: "#E5E5E5" }
                                                    }, value: appendDollar(tivVal) }) })] })), _jsxs(BoxStyled, Object.assign({ sx: { display: 'flex' } }, { children: [_jsx(TextFieldComponent, { width: '210px', label: "Net Retention & Treaty", isRequired: true, isDisabled: true, customInpStyle: {
                                                    style: { backgroundColor: "#E5E5E5", width: "100%" }
                                                }, disabled: true, value: appendDollar(netRetentionAndTreaty) }), _jsxs(BoxStyled, Object.assign({ sx: { display: 'flex' } }, { children: [isUWReferred && (_jsx(BoxStyled, Object.assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: _jsx(ButtonAtom, Object.assign({ sx: { padding: '10px', marginTop: '28px' }, disabled: isAllDisabled || isPreminumFieldsVisible || retrivedIsPremiumUpdatedFlag ? 'isDisabled' : false, id: isAllDisabled || isPreminumFieldsVisible || retrivedIsPremiumUpdatedFlag ? 'recalculatedisabled' : 'recalculate', onClick: (event) => callPricingAPI() }, { children: "RECALCULATE" })) }))), isUWReferred &&
                                                        (showErrorMessage || retrivedIsCalculatedFlag
                                                            ? _jsx(BoxStyled, Object.assign({ sx: { position: 'absolute', left: messageWidth, marginTop: '51px', display: 'flex', flexWrap: 'wrap', width: '180px' }, style: {
                                                                    //margin: '-31px 0px 0px 770px',
                                                                    fontWeight: 'Bold', fontSize: '10px',
                                                                    color: 'red',
                                                                    //  marginBottom: '20px',
                                                                    width: 'fit-content'
                                                                } }, { children: "Please click the RECALCULATE button to proceed" }))
                                                            : null)] }))] }))] })), _jsx(BoxStyled, Object.assign({ sx: { display: 'flex', flexDirection: 'column', marginTop: '12px' } }, { children: _jsx(BoxStyled, Object.assign({ sx: { marginLeft: '10px', marginRight: '10px' } }, { children: _jsx(InputFieldComponent, { label: "Share Requested", disabled: !isUWReferred || isAllDisabled || isPreminumFieldsVisible || retrivedIsPremiumUpdatedFlag, customInpStyle: {
                                            style: {
                                                color: '#000',
                                                height: '20px', padding: "9px 3px 9px 10px",
                                                // backgroundColor: (!isUWReferred || isPreminumFieldsVisible || retrivedIsPremiumUpdatedFlag) ? 'rgb(229, 229, 229)' : "white",
                                            }
                                        }, width: '150px', height: '70px', id: 'ceding', onChange: (e) => handleSharedRequestChange(e.target.value), value: !isUWReferred || isAllDisabled || isPreminumFieldsVisible || retrivedIsPremiumUpdatedFlag ? sharesVal !== null ? appendPercentage(sharesVal === null || sharesVal === void 0 ? void 0 : sharesVal.toString()) : appendPercentage("0") : sharesVal, isCurrency: true, currencySymbol: isUWReferred && !isAllDisabled && !isPreminumFieldsVisible && !retrivedIsPremiumUpdatedFlag ? "%" : "", isPercentage: true, handleTextChange: handleSharedRequestChange, onBlur: formatSharesReq, isDecimalAllowed: true }) })) })), _jsx(BoxStyled, { children: _jsx(TextFieldComponent, { width: "275px", label: "Client Offer (100% Gross Annual Premium)", value: appendDollar(layerAnnualizedClientOfferAmt), isDisabled: true, customInpStyle: {
                                        style: { backgroundColor: "#E5E5E5" }
                                    } }) })] })) })] })));
};
function UpdateRecalculateVisibility(value, setShowErrorMessage, handleRecalculateVisibility) {
    if (value !== "") {
        setShowErrorMessage(true);
        handleRecalculateVisibility(true);
    }
    else {
        setShowErrorMessage(false);
        handleRecalculateVisibility(false);
    }
}
