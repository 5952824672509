/**
 * It takes a theme object and returns a new theme object with the mixins property added to it.
 * @param theme - Omit<ApThemeOptions, 'customMixins' | 'components'>
 * @returns An object with the following properties:
 * - mixins
 * - components
 */
const createTheme = (theme) => {
    return Object.assign(Object.assign({}, theme), { customMixins: {
            subTabHeight: 40,
        }, components: {
            MuiTextField: {
                defaultProps: {
                    variant: 'hover-filled',
                    fullWidth: true,
                },
                styleOverrides: {
                    root: {
                        '&.MuiHoverFilledInput': {
                            '.MuiInputBase-root.Mui-disabled:before': {
                                borderColor: theme.customPalette.grey.borderTransparent,
                            },
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            '&.MuiButton-outlinedPrimary': {
                                backgroundColor: theme.customPalette.grey.hover,
                                color: theme.customPalette.grey.disabled,
                            },
                            '&.MuiButton-containedPrimary': {
                                backgroundColor: theme.customPalette.grey.dragged,
                                color: theme.customPalette.grey.disabled,
                            },
                        },
                    },
                },
            },
            MuiGrid: {
                defaultProps: {
                    alignItems: 'center',
                },
            },
        } });
};
export default createTheme;
