import { createSlice } from '@reduxjs/toolkit';
const currentDate = new Date();
const initialState = {
    lossOfInfoData: {
        numberOfYearsReported: 'Select',
        numberOfLossesOverTBD: 0,
        largeIndividualLoss: 0,
        claimValuationDate: currentDate.toISOString().split('T')[0],
    }
};
const lossOfInfoSlice = createSlice({
    name: 'lossOfInfoDetails',
    initialState,
    reducers: {
        setNumberOfYearsReported: (state, action) => {
            state.lossOfInfoData.numberOfYearsReported = action.payload;
        },
        setNumberOfLossesOverTBD: (state, action) => {
            state.lossOfInfoData.numberOfLossesOverTBD = action.payload;
        },
        setLargeIndividualLoss: (state, action) => {
            state.lossOfInfoData.largeIndividualLoss = action.payload;
        },
        setClaimValuationDate: (state, action) => {
            state.lossOfInfoData.claimValuationDate = action.payload;
        },
        clearLossInfo: (state) => {
            state.lossOfInfoData = initialState.lossOfInfoData;
        },
        setLossInformationBind: (state, action) => {
            var _a;
            //console.log("setLossInformationBind: ",action.payload);
            let _propertySubmissionInfo = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.propertySubmission;
            state.lossOfInfoData.numberOfYearsReported = _propertySubmissionInfo.numberOfYearsReported;
            state.lossOfInfoData.numberOfLossesOverTBD = _propertySubmissionInfo.numberOfLossesOverTBD;
            state.lossOfInfoData.largeIndividualLoss = _propertySubmissionInfo.largeIndividualLoss;
            state.lossOfInfoData.claimValuationDate = _propertySubmissionInfo.claimValuationDate.split('T')[0];
        }
    },
});
export const { clearLossInfo, setNumberOfYearsReported, setNumberOfLossesOverTBD, setLargeIndividualLoss, setClaimValuationDate, setLossInformationBind } = lossOfInfoSlice.actions;
export default lossOfInfoSlice.reducer;
